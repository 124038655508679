import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchStudents,
  addStudent,
  updateStudent,
  deleteStudent,
  setCurrentPage,
} from "../../Redux/Etudent/studentsSlice";
import Table from "react-bootstrap/Table";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Modal from "react-bootstrap/Modal";
import { FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import Modalchoix from "../designChoix/Modalchoix";
import { Pagination, Spinner } from "react-bootstrap";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Etudent.css";
import moment from "moment";

export default function Etudiant() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {
    students,
    totalStudents,
    currentPage = 1,
    pageSize,
    status,
  } = useSelector((state) => state.students);
  const { auth } = useSelector((e) => e);
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const [showModal, setShowModal] = useState(false);
  const [errors, setErrors] = useState({});
  const [isEditing, setIsEditing] = useState(false);
  const [editStudentId, setEditStudentId] = useState(null);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(searchTerm, 1200);
  const [isLoading, setIsLoading] = useState(false);

  console.log("auth", auth);

  // show de modal choix
  const [showModalChoix, setShowModalChoix] = useState(false);
  const [selectedStudentId, setSelectedStudentId] = useState(null);
  const handleShowModalchoix = (studentId) => {
    setSelectedStudentId(studentId);
    setShowModalChoix(true);
  };
  //

  const [with_fee, setWithFee] = useState(true);

  const [formData, setFormData] = useState({
    nom: "",
    prenom: "",
    phone: "",
    parent_phone: "",
    cin: "",
    birthday: "",
    email: "",
    country: "maroc",
    city: "",
  });

  useEffect(() => {
    try {
      if (selectedYear) {
        dispatch(
          fetchStudents({
            yearId: selectedYear,
            page: currentPage,
            pageSize,
            searchTerm: debouncedSearchTerm,
          })
        );
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
    }
  }, [
    dispatch,
    selectedYear,
    currentPage,
    pageSize,
    debouncedSearchTerm,
    navigate,
  ]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
    };
  }, [dispatch]);

  const handleShowModal = (student = null) => {
    if (student) {
      setFormData({
        nom: student.nom,
        prenom: student.prenom,
        phone: student.phone,
        parent_phone: student.parent_phone,
        cin: student.cin,
        birthday: student.birthday,
        email: student.email,
        country: student.country || "maroc",
        city: student.city,
      });
      setEditStudentId(student.id);
      setIsEditing(true);
    } else {
      setFormData({
        nom: "",
        prenom: "",
        phone: "",
        parent_phone: "",
        cin: "",
        birthday: "",
        email: "",
        country: "maroc",
        city: "",
      });
      setEditStudentId(null);
      setIsEditing(false);
    }
    setErrors({});
    setShowModal(true);
  };

  const handleCloseModal = () => setShowModal(false);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleChangewith = (e) => {
    setWithFee(e.target.checked);
  };

  const validateForm = () => {
    const newErrors = {};

    // Validate required fields
    if (!selectedYear) toast.error("Année scolaire est requise");
    if (!formData.nom) newErrors.nom = "Nom est requise";
    if (!formData.prenom) newErrors.prenom = "Prénom est requise";
    if (!formData.birthday)
      newErrors.birthday = "Date de naissance est requise";
    if (!formData.phone) newErrors.phone = "Téléphone est requise";
    if (!formData.country) newErrors.country = "Pays est requise";
    if (!formData.city) newErrors.city = "Ville est requise";
    // Validate phone numbers
    const phonePattern = /^\d{10}$/;
    if (formData.phone && !phonePattern.test(formData.phone)) {
      newErrors.phone = "Le numéro de téléphone doit comporter 10 chiffres";
    }
    if (formData.parent_phone && !phonePattern.test(formData.parent_phone)) {
      newErrors.parent_phone =
        "Le numéro de téléphone du parent doit comporter 10 chiffres";
    }

    // Validate email
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (formData.email && !emailPattern.test(formData.email)) {
      newErrors.email = "Email invalide";
    }

    // Validate date of birth
    const today = new Date().toISOString().split("T")[0];
    if (formData.birthday && new Date(formData.birthday) >= new Date(today)) {
      newErrors.birthday = "La date de naissance doit être dans le passé";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (!validateForm()) return;

    const dataToSend = {
      ...formData,
      year_id: selectedYear,
      nom: formData.nom.toLowerCase(),
      prenom: formData.prenom.toLowerCase(),
      country: formData.country.toLowerCase(),
      city: formData.city.toLowerCase(),
      email: formData.email ? formData.email.toLowerCase() : undefined,
      cin: formData.cin ? formData.cin : undefined,
      parent_phone: formData.parent_phone ? formData.parent_phone : undefined,
      with_fee,
    };

    try {
      setIsLoading(true);
      if (isEditing) {
        await dispatch(
          updateStudent({ id: editStudentId, studentData: dataToSend })
        ).unwrap();
        toast.success("Étudiant mis à jour avec succès");
      } else {
        await dispatch(addStudent(dataToSend)).unwrap();
        toast.success("Étudiant ajouté avec succès");
      }
      dispatch(fetchStudents({ yearId: selectedYear }));
      dispatch(setCurrentPage(1));
      handleCloseModal();
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            break;
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const showDeleteConfirmation = (id) => {
    setStudentToDelete(id);
    setShowDeleteModal(true);
  };

  const handleDelete = async () => {
    if (!studentToDelete) return;

    try {
      await dispatch(deleteStudent(studentToDelete)).unwrap();
      toast.success("Étudiant supprimé avec succès");

      dispatch(
        fetchStudents({
          yearId: selectedYear,
          page: currentPage,
          pageSize,
          searchTerm: debouncedSearchTerm,
        })
      );
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
    } finally {
      setShowDeleteModal(false);
      setStudentToDelete(null);
    }
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    dispatch(setCurrentPage(1));
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  const totalPages = Math.ceil(totalStudents / pageSize);

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des étudiants</h3>
        </Col>
      </Row>
      <Row className="align-items-center mb-3">
        <Col xs={12} md={4} className="text-md-start text-center ">
          <FaPlus
            className="fa-plus"
            style={{ fontSize: "20px", color: "blue", size: "20px" }}
            variant="primary"
            onClick={() => handleShowModal()}
          />
        </Col>
        <Col xs={12} md={4} className="mb-md-0 d-flex justify-content-center">
          <Form.Control
            type="text"
            placeholder="Rechercher par Nom, Prénom, Phone, CIN"
            style={{ width: "100%" }}
            size="sm"
            value={searchTerm}
            onChange={handleSearchChange}
          />
        </Col>
      </Row>

      <div className="table-responsive-etud">
        <Table hover size="sm" responsive bordered>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Nom</th>
              <th>Prénom</th>
              <th>Téléphone</th>
              <th>Téléphone parent</th>
              <th>CIN</th>
              <th>Date Naissance</th>
              <th>Email</th>
              <th>Pays</th>
              <th>Ville</th>
              <th>Etat</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {status === "loading" ? (
              <tr>
                <td colSpan="10" className="text-center">
                  <Spinner animation="border" role="status" />
                  <span className="ms-2">Chargement...</span>
                </td>
              </tr>
            ) : students.length > 0 ? (
              students.map((student, index) => (
                <tr key={student.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(student.nom)}</td>
                  <td>{capitalizeFirstLetter(student.prenom)}</td>
                  <td>{student.phone}</td>
                  <td>{student.parent_phone || "Non existant"}</td>
                  <td>{student?.cin?.toUpperCase() || "Non existant"}</td>
                  <td>{moment(student.birthday).format("YYYY/MM/DD")}</td>
                  <td>{student.email || "Non existant"}</td>
                  <td>{capitalizeFirstLetter(student.country)}</td>
                  <td>{capitalizeFirstLetter(student.city)}</td>
                  <td>
                    {student?.status === "registered" ? (
                      <span
                        className="badge bg-warning"
                        style={{ width: "70px", textAlign: "center" }}
                      >
                        inscrit
                      </span>
                    ) : (
                      <span
                        className="badge bg-success"
                        style={{ width: "70px", textAlign: "center" }}
                      >
                        confirme
                      </span>
                    )}
                  </td>
                  <td className="text-center">
                    <FaTrash
                      className="m-2"
                      style={{ color: "red" }}
                      onClick={() => showDeleteConfirmation(student.id)}
                    />
                    <FaEdit
                      className="m-2"
                      style={{ color: "green" }}
                      onClick={() => handleShowModal(student)}
                    />
                    <FaPlus
                      className="m-2"
                      variant="primary"
                      onClick={() => handleShowModalchoix(student.id)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="10" className="text-center">
                  Aucun étudiant trouvé
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      <Modal show={showModal} onHide={handleCloseModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>
            {isEditing ? "Modifier un étudiant" : "Ajouter un étudiant"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Row>
              <Col>
                <Form.Group controlId="formNom">
                  <Form.Label>
                    Nom <span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="nom"
                    value={formData.nom}
                    onChange={handleChange}
                    isInvalid={!!errors.nom}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.nom}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formPrenom">
                  <Form.Label>
                    Prénom<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="prenom"
                    value={formData.prenom}
                    onChange={handleChange}
                    isInvalid={!!errors.prenom}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.prenom}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formPhone">
                  <Form.Label>
                    Téléphone<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    isInvalid={!!errors.phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formParentPhone">
                  <Form.Label>Téléphone du Parent</Form.Label>
                  <Form.Control
                    type="text"
                    name="parent_phone"
                    value={formData.parent_phone}
                    onChange={handleChange}
                    isInvalid={!!errors.parent_phone}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.parent_phone}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formCin">
                  <Form.Label>CIN</Form.Label>
                  <Form.Control
                    type="text"
                    name="cin"
                    value={formData.cin}
                    onChange={handleChange}
                    isInvalid={!!errors.cin}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.cin}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formBirthday">
                  <Form.Label>
                    Date de Naissance<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="date"
                    name="birthday"
                    value={formData.birthday}
                    onChange={handleChange}
                    isInvalid={!!errors.birthday}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.birthday}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="formEmail">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    isInvalid={!!errors.email}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.email}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
              <Col>
                <Form.Group controlId="formCountry">
                  <Form.Label>
                    Pays<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="country"
                    value={formData.country}
                    onChange={handleChange}
                    isInvalid={!!errors.country}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.country}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            <Row className="mb-2">
              <Col>
                <Form.Group controlId="formCity">
                  <Form.Label>
                    Ville<span style={{ color: "red" }}> *</span>
                  </Form.Label>
                  <Form.Control
                    type="text"
                    name="city"
                    value={formData.city}
                    onChange={handleChange}
                    isInvalid={!!errors.city}
                  />
                  <Form.Control.Feedback type="invalid">
                    {errors.city}
                  </Form.Control.Feedback>
                </Form.Group>
              </Col>
            </Row>
            {!isEditing && auth.user?.fee ? (
              <Row>
                <Col>
                  <Form.Group
                    controlId="fromWithfee"
                    className="d-flex align-items-center"
                  >
                    <Form.Check
                      type="checkbox"
                      name="with_fee"
                      checked={with_fee}
                      onChange={handleChangewith}
                      className="mb-2"
                      style={{ marginRight: "2px" }}
                    />
                    <Form.Label>Avec frais d'inscription</Form.Label>
                  </Form.Group>
                </Col>
              </Row>
            ) : (
              ""
            )}

            <Button
              variant="primary"
              className="float-end"
              onClick={handleSubmit}
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {" Enregistrement..."}
                </>
              ) : isEditing ? (
                "Modifier"
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Form>
        </Modal.Body>
      </Modal>

      <Modal show={showDeleteModal} onHide={() => setShowDeleteModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la Suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet étudiant ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={() => setShowDeleteModal(false)}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDelete}>
            Supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modalchoix
        show={showModalChoix}
        studentId={selectedStudentId}
        selectedYear={selectedYear} // Pass it as a prop
        setShowModalChoix={setShowModalChoix}
      />
    </>
  );
}
