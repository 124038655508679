import React, { useEffect, useState } from "react";
import {
  Button,
  Col,
  Form,
  Modal,
  Pagination,
  Row,
  Spinner,
  Table,
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import "./formation.css";
import {
  fetchFormations,
  addFormation,
  updateFormation,
  deleteFormation,
  setCurrentPage,
} from "../../Redux/formation/formationSlice";
import { fetchCategories } from "../../Redux/categories/categoriesSlice";
import { fetchLevelsByFormation } from "../../Redux/levels/levelsSlice";
import { fetchProfessors } from "../../Redux/Profs/professorsSlice";
import { determineChoice } from "../../Redux/choix/teachingChoiceSlice";
import { addGroup, resetError } from "../../Redux/groupe/Group";
import { FaChalkboardTeacher, FaEdit, FaPlus, FaTrash } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export const Formation = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeId, setActiveId] = useState(null);
  const [selectCategoryFormation, setSelectCategoryFormation] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [showGroupModal, setShowGroupModal] = useState(false);
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const [professorSearchTerm, setProfessorSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(professorSearchTerm, 600);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingGroup, setIsLoadingGroup] = useState(false);

  const [formData, setFormData] = useState({
    id: null,
    name: "",
    categorie_id: "",
  });

  const [formDataGroupe, setFormDataGroupe] = useState({
    formation_id: "",
    level_id: "",
    choice_id: "",
    teacher_id: "",
    name: "",
    year_id: selectedYear,
  });

  const levels = useSelector((state) => state.levels.levels);

  const [selectedProfessor, setSelectedProfessor] = useState("");
  const categories = useSelector((state) => state.categories.categories);
  const {
    totalFormations,
    currentPage = 1,
    pageSize,
    status,
  } = useSelector((state) => state.formations);
  const formations = useSelector((state) => state.formations.formations);
  const professors = useSelector((state) => state.professors.professors);
  const determines = useSelector(
    (state) => state.teachingChoices.teachingChoices
  );
  const [showConfirmDeleteModal, setShowConfirmDeleteModal] = useState(false);
  const [formationToDelete, setFormationToDelete] = useState(null);

  useEffect(() => {
    dispatch(fetchCategories());
  }, [dispatch]);

  useEffect(() => {
    if (showGroupModal) {
      dispatch(fetchProfessors({ searchTerm: debouncedSearchTerm }));
    }
  }, [dispatch, debouncedSearchTerm, showGroupModal]);

  useEffect(() => {
    if (formDataGroupe.formation_id) {
      const fetchData = async () => {
        try {
          await dispatch(
            fetchLevelsByFormation(formDataGroupe.formation_id)
          ).unwrap();
        } catch (error) {}
      };

      fetchData();
    }
  }, [formDataGroupe.formation_id, dispatch]);

  useEffect(() => {
    const fetchChoiceData = async () => {
      if (showGroupModal) {
        try {
          await dispatch(
            determineChoice({
              formationId: formDataGroupe.formation_id,
              levelId: formDataGroupe.level_id,
            })
          ).unwrap();
        } catch (error) {}
      }
    };
    fetchChoiceData();
  }, [
    formDataGroupe.formation_id,
    formDataGroupe.level_id,
    dispatch,
    showGroupModal,
  ]);

  useEffect(() => {
    dispatch(
      fetchFormations({
        selectCategoryFormation,
        page: currentPage,
        pageSize,
        categorie: selectCategoryFormation,
      })
    );
  }, [selectCategoryFormation, dispatch, currentPage, pageSize]);

  useEffect(() => {
    return () => {
      dispatch(setCurrentPage(1));
    };
  }, [dispatch]);

  const totalPages = Math.ceil(totalFormations / pageSize);

  // filter par groupe
  const filteredDetermines = determines.filter(
    (determine) => determine.teaching_method === "Group"
  );

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormDataGroupe((prevData) => ({ ...prevData, [name]: value }));
  };

  const handleShowModal = (
    formation = { id: null, name: "", categorie_id: "" }
  ) => {
    setFormData(formation);
    setShowModal(true);
    console.log("formation",formation);

  };

  const handleCloseModal = () => {
    setShowModal(false);
    setFormData({ id: null, name: "", categorie_id: "" });
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validateFormData = (formData) => {
    const errors = [];

    if (!formData.name.trim()) {
      errors.push("Nom de formation est requise.");
    }

    if (!formData.categorie_id) {
      errors.push("Catégorie est requise.");
    }

    if (!selectedYear) {
      errors.push("Anne scolaire est requise");
    }

    return errors;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const errors = validateFormData(formData);

    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    const formationData = {
      ...formData,
      name: formData?.name?.trim()?.toLowerCase(),
    };

    try {
      setIsLoading(true);
      if (formData.id) {
        await dispatch(
          updateFormation({ id: formData.id, updatedData: formationData })
        ).unwrap();
      } else {
        await dispatch(addFormation(formationData)).unwrap();
      }
      dispatch(fetchFormations(selectCategoryFormation));
      toast.success(
        formData.id
          ? "Formation mise à jour avec succès!"
          : "Formation ajoutée avec succès!"
      );
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      handleCloseModal();
      setIsLoading(false);
    }
  };

  const handleShowConfirmDeleteModal = (id) => {
    setFormationToDelete(id);
    setShowConfirmDeleteModal(true);
  };

  const handleCloseConfirmDeleteModal = () => {
    setShowConfirmDeleteModal(false);
    setFormationToDelete(null);
  };

  const confirmDelete = async () => {
    if (formationToDelete) {
      try {
        await dispatch(deleteFormation(formationToDelete)).unwrap();
        toast.success("Formation supprimée avec succès!");
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        handleCloseConfirmDeleteModal();
        dispatch(fetchFormations(selectCategoryFormation));
      }
    }
  };

  const handleShowGroupModal = (formationId) => {
    setFormDataGroupe({
      ...formDataGroupe,
      formation_id: formationId,
      year_id: selectedYear,
      level_id: "",
      choice_id: "",
    });
    setShowGroupModal(true);
    setSelectedProfessor("");
    setActiveId(null);
    setShowGroupModal(true);
  };

  const handleCloseGroupModal = async () => {
    dispatch(resetError());
    setShowGroupModal(false);
    setSelectedProfessor("");
    setActiveId(null);
  };

  const handleTeacherChange = (selectedOption) => {
    if (selectedOption) {
      const teacherId = selectedOption.value;
      setSelectedProfessor(teacherId);
    }
  };

  const validateGroupData = (groupData) => {
    const errors = [];

    if (!groupData.formation_id) {
      errors.push("La formation est requise.");
    }

    if (!groupData.choice_id) {
      errors.push("Le choix est requise.");
    }

    if (!groupData.year_id) {
      errors.push("La sélection de l'année est requise.");
    }

    if (!groupData.name || groupData.name.trim() === "") {
      errors.push("Le nom du groupe est requise.");
    }

    if (!selectedProfessor) {
      errors.push("Le professeur est requise.");
    }

    return errors;
  };

  const handleSubmitGroup = async (e) => {
    e.preventDefault();

    const errors = validateGroupData(formDataGroupe);
    if (errors.length > 0) {
      errors.forEach((error) => toast.error(error));
      return;
    }

    const groupData = {
      ...formDataGroupe,
      teacher_id: selectedProfessor,
    };

    if (!formDataGroupe.level_id) {
      delete groupData.level_id;
    }

    try {
      setIsLoadingGroup(true);
      await dispatch(addGroup(groupData)).unwrap();
      handleCloseGroupModal();
      toast.success("Groupe ajouté avec succès!");
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    } finally {
      setIsLoadingGroup(false);
    }
  };

  console.log("formDataGroupe", formDataGroupe);

  const handleLevelChange = (e) => {
    const levelId = e.target.value;
    setFormDataGroupe((prevData) => ({ ...prevData, level_id: levelId }));
};


  const handleChoiceClick = (determine) => {
    setFormDataGroupe({
      ...formDataGroupe,
      choice_id: determine.id,
    });
    setActiveId(determine.id);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str; // Si la chaîne est vide, on retourne la chaîne vide
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSelectCategoryFormation(value);
    dispatch(setCurrentPage(1));
  };

  const handlePageChange = (page) => {
    dispatch(setCurrentPage(page));
  };

  console.log("formData",formData);

  return (
    <>
      <Row className="align-items-center ">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des Formations</h3>
        </Col>
      </Row>
      <Row className="align-items-center mb-2">
        <Col
          xs={12}
          md={4}
          className="d-flex justify-content-center justify-content-xl-start me-md-auto mb-2 mb-md-0"
        >
          <FaPlus
            className="fa-plus"
            style={{ fontSize: "20px", color: "blue", size: "20px" }}
            variant="primary"
            onClick={() => handleShowModal()}
          />
        </Col>

        <Col
          xs={12}
          md={3}
          className="mb-2 mb-md-0 d-flex justify-content-center"
        >
          <Form.Select
            size="sm"
            value={selectCategoryFormation}
            onChange={handleSearchChange}
          >
            <option value="">Toutes les catégories</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>
                {capitalizeFirstLetter(category.name)}
              </option>
            ))}
          </Form.Select>
        </Col>
      </Row>

      <div className="table-responsive-etud">
        <Table hover size="sm" responsive bordered>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Formation</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody className="text-center">
            {status === "loading" ? (
              <tr>
                <td colSpan="3" className="text-center">
                  <div className="d-flex align-items-center justify-content-center">
                    <Spinner animation="border" role="status" />
                    <span className="ms-2">Chargement...</span>
                  </div>
                </td>
              </tr>
            ) : formations.length > 0 ? (
              formations.map(({ id, name , categorie_id }, index) => (
                <tr key={id}>
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(name)}</td>
                  <td className="text-center">
                    <FaEdit
                      className="m-2"
                      style={{ cursor: "pointer", color: "green" }}
                      onClick={() => handleShowModal({ id, name , categorie_id })}
                      title="Modifier"
                    />
                    <FaTrash
                      className="m-2"
                      style={{ cursor: "pointer", color: "red" }}
                      onClick={() => handleShowConfirmDeleteModal(id)}
                      title="Supprimer"
                    />
                    <FaPlus
                      className="m-2"
                      style={{ cursor: "pointer", color: "blue" }}
                      onClick={() => handleShowGroupModal(id)}
                      title="Ajouter groupe"
                    />
                    <Link to={`/dashboard/groups/${id}`}>
                      <FaChalkboardTeacher
                        className="m-2"
                        style={{ cursor: "pointer", color: "mediumorchid" }}
                        title="Voir les groupes"
                      />
                    </Link>
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="3" className="text-center">
                  Aucun Formation trouvé
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Row className="justify-content-center">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      {/* Ajouter une formation et edit   */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {formData.id
              ? "Mettre à jour la formation"
              : "Ajouter une formation"}
          </Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmit}>
          <Modal.Body>
            <Form.Group controlId="formFormationName">
              <Form.Control
                type="text"
                placeholder="Enter formation"
                name="name"
                value={formData.name}
                onChange={handleInputChange}
              />
            </Form.Group>
            <Form.Group controlId="formFormationCategory" className="mt-3">
              <Form.Select
                name="categorie_id"
                value={formData.categorie_id}
                onChange={handleInputChange}
              >
                <option value="">sélectionner categorie</option>
                {categories.map((category) => (
                  <option key={category.id} value={category.id}>
                    {capitalizeFirstLetter(category.name)}
                  </option>
                ))}
              </Form.Select>
            </Form.Group>
          </Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              className="mr-auto"
              type="submit"
              disabled={isLoading}
            >
              {isLoading ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {formData.id ? "Modifierement..." : "Enregistrement..."}
                </>
              ) : formData.id ? (
                "Modifier"
              ) : (
                "Enregistrer"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* add groupe  */}
      <Modal show={showGroupModal} onHide={handleCloseGroupModal} size="lg">
        <Modal.Header closeButton>
          <Modal.Title>Ajouter Groupe</Modal.Title>
        </Modal.Header>
        <Form onSubmit={handleSubmitGroup}>
          <Modal.Body>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>Nom de groupe:</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    onChange={handleChange}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row className="mt-4">
              <Col md={levels && levels.length > 0 ? 6 : 12}>
                <Form.Group controlId="formProfessorSelect">
                  <Select
                    options={professors?.map((p) => ({
                      value: p.id,
                      label: `${p.nom} ${p.prenom}`,
                    }))}
                    placeholder="sélectionner un prof"
                    onChange={handleTeacherChange}
                    onInputChange={(inputValue) =>
                      setProfessorSearchTerm(inputValue)
                    }
                    inputValue={professorSearchTerm} // Controlled input for search
                  />
                </Form.Group>
              </Col>
              {levels && levels.length > 0 && (
                <Col>
                  <Form.Group controlId="formLevelSelect">
                    <Form.Select
                      name="level_id"
                      onChange={handleLevelChange}
                      value={formDataGroupe.level_id}
                    >
                      <option>sélectionner Niveau</option>
                      {levels.map((level) => (
                        <option key={level.id} value={level.id}>
                          {level?.name?.toUpperCase()}
                        </option>
                      ))}
                    </Form.Select>
                  </Form.Group>
                </Col>
              )}
            </Row>
            <Row>
              <Col>
                <div className="tickets">
                  <h5>Choix:</h5>
                  <ul>
                    {filteredDetermines.map((determine) => (
                      <li
                        key={determine.id}
                        className={`ticket ${
                          activeId === determine.id ? "active" : ""
                        }`}
                        onClick={() => handleChoiceClick(determine)}
                        style={{ cursor: "pointer" }}
                      >
                        <p className="price">{determine.price} Dh</p>
                        <div className="numbers">
                          <p>{determine.session_duration} h / Séance</p>
                          <p>{determine.session_nb} Séance/Semaine</p>
                          <p>
                            {determine?.formation_duration === 0
                              ? "Par mois"
                              : determine?.formation_duration + " Mois"}
                          </p>
                        </div>
                      </li>
                    ))}
                  </ul>
                </div>
              </Col>
            </Row>
          </Modal.Body>
          <Modal.Footer>
            <Button variant="primary" type="submit" className="mr-auto">
              {isLoadingGroup ? (
                <>
                  <Spinner animation="border" size="sm" />
                  {"chargement"}
                </>
              ) : (
                "Enregistrer Groupe"
              )}
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>

      {/* modal delete */}
      <Modal
        show={showConfirmDeleteModal}
        onHide={handleCloseConfirmDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cette formation ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseConfirmDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};
