import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate, { axiosPublic } from "../../api/axios";

export const login = createAsyncThunk(
  "auth/login",
  async (credentials, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.post("/login", credentials);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const register = createAsyncThunk(
  "auth/register",
  async (userData, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.post("/register", userData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const refresh = createAsyncThunk(
  "auth/refresh",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPublic.get("/refresh");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const logout = createAsyncThunk(
  "auth/logout",
  async (_, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get("/logout");
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);


// Action pour la vérification de l'email
export const verifyEmail = createAsyncThunk(
  "auth/verifyEmail",
  async (token, { rejectWithValue }) => {
    try {
      // Envoi de la requête pour vérifier l'email avec le token
      const response = await axiosPublic.get(`/verify-email/${token}`);
      return response.data; // Retourne la réponse du backend
    } catch (error) {
      return rejectWithValue(error.response.data); // Retourne l'erreur du backend
    }
  }
);

const authSlice = createSlice({
  name: "auth",
  initialState: {
    user: null,
    accessToken: null,
    loading: false,
    error: null,
    verifyEmailStatus: "idle",
    verifyEmailError: null,

  },
  reducers: {

    updateFeeAndSpecificDay(state, action) {
      if (state.user) {
        state.user.fee = action.payload.fee;
        state.user.specific_day = action.payload.specific_day;
        state.user.payment_type = action.payload.payment_type;
        console.log("attt", action.payload.payment_type)
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(login.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(login.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(register.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(register.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(register.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(refresh.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(refresh.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload.user;
        state.accessToken = action.payload.accessToken;
      })
      .addCase(refresh.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(logout.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(logout.fulfilled, (state) => {
        state.loading = false;
        state.user = null;
        state.accessToken = null;
      })
      .addCase(logout.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })

      // Pour verifyEmail
      .addCase(verifyEmail.pending, (state) => {
        state.verifyEmailStatus = "loading"; 
        state.verifyEmailError = null;
      })
      .addCase(verifyEmail.fulfilled, (state, action) => {
        state.verifyEmailStatus = "succeeded"; 
        state.verifyEmailError = null;
      })
      .addCase(verifyEmail.rejected, (state, action) => {
        state.verifyEmailStatus = "failed"; 
        state.verifyEmailError = action.payload;
      });
  },
});

export const { updateFeeAndSpecificDay } = authSlice.actions;
export default authSlice.reducer;
