import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchStudents = createAsyncThunk(
  "students/fetchStudents",
  async (
    { yearId, page = 1, pageSize = 10, searchTerm },
    { rejectWithValue }
  ) => {
    try {
      const response = await axiosPrivate.get(`/students/${yearId}`, {
        params: { page, pageSize, searchTerm },
      });
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addStudent = createAsyncThunk(
  "students/addStudent",
  async (studentData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/students", studentData);
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const updateStudent = createAsyncThunk(
  "students/updateStudent",
  async ({ id, studentData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `/students/${id}`,
        studentData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "students/deleteStudent",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/students/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const studentsSlice = createSlice({
  name: "students",
  initialState: {
    students: [],
    selectedYear: "",
    status: "idle",
    error: null,
    totalStudents: 0,
    currentPage: 1,
    pageSize: 10,
  },
  reducers: {
    setSelectedYear(state, action) {
      state.selectedYear = action.payload;
      localStorage.setItem("lastSelectedYear", action.payload);
    },
    clearError(state) {
      state.error = null;
    },
    setCurrentPage(state, action) {
      state.currentPage = action.payload;
    },
    clearStudents(state) {
      state.students = [];
      state.totalStudents = 0;
      state.selectedYear = "";
      state.currentPage = 1;
      state.status = "idle";
      state.error = null;
    },
    updateStudentStatusInState(state, action) {
      const { id, newStatus } = action.payload;
      const index = state.students.findIndex((student) => student.id === id);
      if (index >= 0) {
        state.students[index].status = newStatus; 
      }
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.students = action.payload.students;
        state.totalStudents = action.payload.totalStudents;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })

      .addCase(addStudent.fulfilled, (state, action) => {
        state.students.push(action.payload);
      })
      .addCase(addStudent.pending, (state) => {
        state.status = "loading";
      })
      .addCase(addStudent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        const index = state.students.findIndex(
          (student) => student.id === action.payload.id
        );
        if (index >= 0) {
          state.students[index] = action.payload;
        }
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        state.students = state.students.filter(
          (student) => student.id !== action.payload
        );
      });
  },
});

export const { setSelectedYear, clearError, setCurrentPage , clearStudents , updateStudentStatusInState } =
  studentsSlice.actions;

export default studentsSlice.reducer;
