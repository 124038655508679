import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchAbsences = createAsyncThunk(
  "absences/fetchAbsences",
  async (sessionId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(
        `/group-session-absences/${sessionId}`
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

export const addAbsence = createAsyncThunk(
  "absences/addAbsence",
  async (absenceData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(
        "/group-session-absences",
        absenceData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const absenceSlice = createSlice({
  name: "absences",
  initialState: {
    absences: [], 
    loading: false,
    error: null,
  },
  reducers: {
    clearAbsences: (state) => {
      state.absences = [];
    },
    setAbsences: (state,action) => {
      state.absences = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAbsences.pending, (state) => {
        state.loading = true;
        state.absences = [];
        state.error = null;
      })
      .addCase(fetchAbsences.fulfilled, (state, action) => {
        state.loading = false;
        state.absences = action.payload;
      })
      .addCase(fetchAbsences.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(addAbsence.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(addAbsence.fulfilled, (state, action) => {
        state.loading = false;
        // state.absences = action.payload;
        // state.absences = [...state.absences, action.payload];
      })
      .addCase(addAbsence.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

export const { clearAbsences ,setAbsences} = absenceSlice.actions;
export default absenceSlice.reducer;
