import React, { useEffect, useState } from "react";
import { Table, Modal, Button, Row, Col, Pagination } from "react-bootstrap";
import { FaEye, FaMoneyBill, FaTimesCircle, FaTrash } from "react-icons/fa";
import {
  deleteTeachingStudent,
  fetchTeachingStudents,
  updateTeachingStudent,
} from "../../Redux/teachingStudents/teachingStudentSlice";
import { fetchLevelsByFormation } from "../../Redux/levels/levelsSlice";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useDispatch, useSelector } from "react-redux";
import { fetchStudents } from "../../Redux/Etudent/studentsSlice";
import { determineChoice } from "../../Redux/choix/teachingChoiceSlice";
import { useDebounce } from "use-debounce";
import { useParams } from "react-router-dom";
import { fetchStudentPayments } from "../../Redux/payment/paymentSlice";
import moment from "moment";

const IndividualTable = ({ selectedYear, handlePageChange, activeTab }) => {
  const { formationId } = useParams();

  const dispatch = useDispatch();

  const { teachingStudents, totalTeachingStudents, currentPage, pageSize } =
    useSelector((state) => state.teachingStudents);

  const paymentDetails = useSelector((state) => state.payments.paymentDetails);
  const [idStudent, SetIdstudent] = useState("");
  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);

  const handleViewDetails = (payment) => {
    setShowDetailModal(true);
    setSelectedPaymentDetails(payment);
  };

  const [currentTeachingStudent, setCurrentTeachingStudent] = useState(null);
  const [showTeachingStudentDeleteModal, setShowTeachingStudentDeleteModal] =
    useState(false);
  const [studentSearchTerm] = useState("");
  const [debouncedStudentSearchTerm] = useDebounce(studentSearchTerm, 600);

  const [formData] = useState({
    formation_id: "",
    student_id: "",
    level_id: "",
    teacher_id: "",
    choice_id: "",
  });

  const [showStatusConfirmationModal, setShowStatusConfirmationModal] =
    useState(false);
  const [currentTeachingStatus, setCurrentTeachingStatus] = useState(null);

  const totalPages = Math.ceil(totalTeachingStudents / pageSize);

  useEffect(() => {
    if (activeTab === "indvidual" && formationId) {
      const fetchTeachingStudentsData = async () => {
        try {
          await dispatch(
            fetchTeachingStudents({ page: currentPage, pageSize, formationId })
          ).unwrap();
          await dispatch(fetchLevelsByFormation(formationId)).unwrap();
        } catch (err) {}
      };
      fetchTeachingStudentsData();
    }
  }, [formationId, activeTab, dispatch, currentPage, pageSize]);
  // Fetch students effect
  useEffect(() => {
    if (selectedYear) {
      dispatch(
        fetchStudents({
          yearId: selectedYear,
          searchTerm: debouncedStudentSearchTerm,
        })
      );
    }
  }, [debouncedStudentSearchTerm, dispatch, selectedYear]);

  useEffect(() => {
    const determineChoiceData = async () => {
      try {
        if (formationId) {
          await dispatch(
            determineChoice({ formationId, levelId: formData.level_id })
          ).unwrap();
        }
      } catch (err) {}
    };

    determineChoiceData();
  }, [formationId, formData.level_id, dispatch]);

  const confirmTeachingStudentDelete = async () => {
    if (currentTeachingStudent) {
      await dispatch(deleteTeachingStudent(currentTeachingStudent.id)).unwrap();
      await dispatch(
        fetchTeachingStudents({ page: currentPage, pageSize, formationId })
      ).unwrap();
      toast.success("Étudiant à enseigner supprimé avec succès!");
    }
    handleCloseDeleteModal();
  };

  const handleCloseDeleteModal = () => {
    setShowTeachingStudentDeleteModal(false);
    setCurrentTeachingStudent(null);
  };

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  useEffect(() => {
    if (idStudent && teachingStudents.length > 0) {
      const selectedStudent = teachingStudents.find(
        (student) => student.student.id === idStudent
      );
      if (selectedStudent) {
        dispatch(
          fetchStudentPayments({
            student_id: idStudent,
            ticket_id: selectedStudent.id,
          })
        );
      }
    }
  }, [dispatch, idStudent, teachingStudents]);

  const handleStatusChangeConfirmation = (teaching) => {
    setCurrentTeachingStatus(teaching);
    setShowStatusConfirmationModal(true);
  };

  const confirmStatusChange = async () => {
    const teaching = currentTeachingStatus;
    if (teaching) {
      const updatedStatus =
        teaching.status === "finish" ? "in progress" : "finish";
      try {
        await dispatch(
          updateTeachingStudent({
            id: teaching.id,
            studentData: { status: updatedStatus },
          })
        ).unwrap();
        dispatch(fetchTeachingStudents({ formationId }));
        toast.success(
          `Le statut de l'étudiant a été ${
            updatedStatus === "finish" ? "terminé" : "en cours"
          } avec succès!`
        );
      } catch (error) {
        toast.error("Échec de la mise à jour du statut.");
      }
    }
    setShowStatusConfirmationModal(false);
  };

  const handleCloseStatusConfirmationModal = () => {
    setShowStatusConfirmationModal(false);
    setCurrentTeachingStatus(null);
  };

  return (
    <>
      <div className="table-responsive-etud">
        <Table hover size="lg" responsive className="mt-3" bordered>
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Étudiant</th>
              <th>Niveau</th>
              <th>Prof</th>
              <th>Choix Méthode</th>
              <th>Durée Formation</th>
              <th>Nbr Séance par Semaine</th>
              <th>Durée Séance</th>
              <th>Prix</th>
              <th>Etat</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {teachingStudents.map((teaching, index) => (
              <tr key={teaching.id} className="text-center">
                <td>{index + 1}</td>
                <td>
                  {capitalizeFirstLetter(teaching.student?.nom) +
                    " " +
                    capitalizeFirstLetter(teaching.student?.prenom)}
                </td>
                <td>{teaching.level?.name?.toUpperCase() || "Non existant"}</td>
                <td>
                  {capitalizeFirstLetter(teaching.teacher?.nom) +
                    " " +
                    capitalizeFirstLetter(teaching.teacher?.prenom)}
                </td>
                <td>
                  {teaching.choice?.teaching_method === "At home"
                    ? "À Domicile"
                    : teaching.choice?.teaching_method === "Group"
                    ? "Groupe"
                    : teaching.choice?.teaching_method === "Individual"
                    ? "Individuel"
                    : teaching.choice?.teaching_method}
                </td>
                <td>
                  {teaching.choice?.formation_duration === 0
                    ? "Par mois"
                    : teaching.choice?.formation_duration + " Mois"}
                </td>

                <td>{teaching.choice?.session_nb}</td>
                <td>{teaching.choice?.session_duration}H</td>
                <td>{teaching.choice?.price}Dh</td>
                <td className="text-center" style={{ textAlign: "center" }}>
                  <span
                    className={`badge ${
                      teaching.status === "finish" ? "bg-danger" : "bg-success"
                    }`}
                  >
                    {teaching.status === "finish" ? "Terminé" : "En cours"}
                  </span>
                </td>
                <td className="text-center">
                  <div className="d-flex justify-content-center">
                    {teaching.status === "in progress" && (
                      <FaTimesCircle
                        className="m-2 "
                        style={{ color: "red" }}
                        onClick={() => handleStatusChangeConfirmation(teaching)}
                      />
                    )}
                    <FaTrash
                      className="m-2"
                      style={{ color: "red" }}
                      onClick={() => {
                        setCurrentTeachingStudent(teaching);
                        setShowTeachingStudentDeleteModal(true);
                      }}
                    />

                    <FaMoneyBill
                      className="m-2"
                      size={21}
                      style={{ color: "#4caf50", cursor: "pointer" }}
                      onClick={() => {
                        SetIdstudent(teaching.student.id);
                        setShowPaymentModal(true);
                      }}
                    />
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>

      {/* Pagination Controls */}
      <Row className="justify-content-center mt-3">
        <Col xs="auto">
          <Pagination>
            <Pagination.First
              onClick={() => handlePageChange(1)}
              disabled={currentPage === 1}
            />
            <Pagination.Prev
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1}
            />
            {Array.from({ length: totalPages }, (_, index) => (
              <Pagination.Item
                key={index + 1}
                active={index + 1 === currentPage}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </Pagination.Item>
            ))}
            <Pagination.Next
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages}
            />
            <Pagination.Last
              onClick={() => handlePageChange(totalPages)}
              disabled={currentPage === totalPages}
            />
          </Pagination>
        </Col>
      </Row>

      {/* Delete Confirmation Modal */}
      <Modal
        show={showTeachingStudentDeleteModal}
        onHide={handleCloseDeleteModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirm suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Êtes-vous sûr de vouloir supprimer l'étudiant à enseigner "
            {currentTeachingStudent?.student?.nom}
            {currentTeachingStudent?.student?.prenom}"?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={() => setShowTeachingStudentDeleteModal(false)}
          >
            Cancel
          </Button>
          <Button variant="danger" onClick={confirmTeachingStudentDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* affiche paiment */}
      <Modal
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Paiement Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered responsive>
            <thead>
              <tr className="text-center">
                <th>N</th>
                <th>Montant</th>
                <th>Reste</th>
                <th>Paye</th>
                <th>Etat</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails.map((payment, index) => {
                const totalPartials =
                  payment.partials?.reduce(
                    (sum, partial) => sum + partial.amount,
                    0
                  ) || 0;
                const remainingAmount = payment.amount - totalPartials;

                return (
                  <tr key={payment.id} className="text-center">
                    <td>{index + 1}</td>
                    <td>{payment.amount} Dh</td>
                    <td>{remainingAmount} Dh</td>
                    <td>{totalPartials} Dh</td>
                    <td>
                      {payment.status === 0 ? (
                        <span
                          className="badge bg-danger"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Impayé
                        </span>
                      ) : payment.status === 1 ? (
                        <span
                          className="badge bg-success"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Complété
                        </span>
                      ) : payment.status === 2 ? (
                        <span
                          className="badge bg-warning"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Restant
                        </span>
                      ) : null}
                    </td>
                    <td>{moment(payment.invoice_date).format("YYYY/MM/DD")}</td>
                    <td>
                      <div className="text-center d-flex justify-content-center">
                        <FaEye
                          className="m-2"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => handleViewDetails(payment)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* modal for details partails  */}
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Détails des Paiements Partiels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPaymentDetails && (
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th>N</th>
                  <th>Date </th>
                  <th>Méthode </th>
                  <th>Montant</th>
                </tr>
              </thead>
              <tbody>
                {selectedPaymentDetails.partials?.map((partial, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      {moment(partial.paid_on).format("YYYY/MM/DD HH:mm:ss")}
                    </td>

                    <td>
                      {" "}
                      {partial.payment_method === "Cash"
                        ? "Espèce"
                        : partial.payment_method === "Western union/CashPlus"
                        ? "CashPlus"
                        : partial.payment_method === "Bank transfer"
                        ? "Virement Bancaire"
                        : partial.payment_method}
                    </td>
                    <td>{partial.amount} Dh</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
            Fermer
          </Button>
        </Modal.Body>
      </Modal>

      {/* modal confirmation status */}
      <Modal
        show={showStatusConfirmationModal}
        onHide={handleCloseStatusConfirmationModal}
      >
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de fin d'étude</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Êtes-vous sûr de vouloir confirmer de la fin d'etude{" "}
            {currentTeachingStatus?.student?.nom}{" "}
            {currentTeachingStatus?.student?.prenom}?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            onClick={handleCloseStatusConfirmationModal}
          >
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmStatusChange}>
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default IndividualTable;
