import React, { useCallback, useEffect, useState } from "react";
import {Button, Form } from "react-bootstrap";
import { fetchFormationsWithCategories } from "../../Redux/formation/formationSlice";
import { fetchRooms } from "../../Redux/room/roomsSlice";
import { fetchGroups, fetchGroupById } from "../../Redux/groupe/Group";
import { useDispatch, useSelector } from "react-redux";
import {
  addSession,
  fetchSessions,
  fetchStudentsOfSession,
  oneSession,
  updateSession,
  deleteSession,
  deleteRepeating,
} from "../../Redux/groupSessions/groupSessions";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import "moment/locale/fr";
import "react-big-calendar/lib/css/react-big-calendar.css";
import "./calendar.css";
import "./barSelection.css";
import GroupSelector from "./GroupSelector";
import AddSessionModal from "./AddSessionModal";
import RepeatSessionModal from "./RepeatSessionModal";
import GroupDetailsModal from "./GroupDetailsModal";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import EventDetailsModal from "./EventDetailsModal";
import EventDetailsIndividual from "./EventDetailsIndividual";
import EventDetailsTeaching from "./EventDetailsTeaching";

import {
  fetchOneTeachingStudent,
  fetchTeachingStudents,
} from "../../Redux/teachingStudents/teachingStudentSlice";
import {
  addTeachingSession,
  deleteTeachingSession,
  fetchOneTeachingSession,
  fetchTeachingSessions,
} from "../../Redux/teacheingSession/teachingSessionSlice";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { FaPlus } from "react-icons/fa";
moment.locale("fr");
const localizer = momentLocalizer(moment);

const Calendarier = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [selectedFormation, setSelectedFormation] = useState(null);
  const [selectedGroupId, setSelectedGroupId] = useState(null);
  const formationsWithCategories = useSelector((state) => state.formations.formationsWithCategories);
  const { groups } = useSelector((state) => state.groups);
  const rooms = useSelector((state) => state.rooms.rooms);
  const [groupDetails, setGroupDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isRemote, setIsRemote] = useState(true);
  const [skip, setSkip] = useState(false);
  const [showSecondModal, setShowSecondModal] = useState(false);
  const [showGroupDetailsModal, setShowGroupDetailsModal] = useState(false);
  const [repeatOption, setRepeatOption] = useState(null);
  const [intervalValue, setIntervalValue] = useState(1);
  const [students,setStudents] = useState()
  const [selectedDays, setSelectedDays] = useState([]);
  const [selectedRoomId, setSelectedRoomId] = useState(null);
  const [repeated, setRepeated] = useState(false);
  const [events, setEvents] = useState([]);
  const [startDate, setStartDate] = useState(null);
  const [startTime, setStartTime] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [endTime, setEndTime] = useState(null);
  const [teacherAbsent, setTeacherAbsent] = useState(false);
  const [eventDetailsModalVisible, setEventDetailsModalVisible] =
    useState(false);
  const [eventDetailsModalIndividual, setEventDetailsModalIndividual] =
    useState(false);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [DetailsSession, setDetailsSession] = useState([]);
  const [loading, setLoading] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const [selectedTeachingStudentId, setSelectedTeachingStudentId] =
    useState(null);
  // const [teachingSessions, setTeachingSessions] = useState([]);
  const [selectionType, setSelectionType] = useState("group");
  const teachingStudents = useSelector(
    (state) => state.teachingStudents.teachingStudents
  );
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const [showTeachingDetailsModal, setShowTeachingDetailsModal] =
    useState(false);
  const [detailsTeaching, setDetailsTeaching] = useState([]);
  const [currentWeekStart, setCurrentWeekStart] = useState(
    moment().startOf("week")
  );

  const handleShowTeachingDetails = (id) => {
    if (detailsTeaching) {
      setSelectedTeachingStudentId(id); // Correctly update the state
      setShowTeachingDetailsModal(true);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectionType === "individual" && selectedFormation) {
          await dispatch(
            fetchTeachingStudents({ formationId: selectedFormation.value })
          ).unwrap();
        }
      } catch (err) {
      }
    };
    fetchData();
  }, [selectionType, dispatch, selectedFormation]);


  const handleCheckboxChangeProf = async () => {
    const newAbsenceStatus = !teacherAbsent;
    setTeacherAbsent(newAbsenceStatus);

    try {
      await dispatch(
        updateSession({
          id: DetailsSession.session?.id,
          sessionData: { teacher_absent: newAbsenceStatus },
        })
      ).unwrap();
      toast.success("Mise à jour réussie!!");
      handleClose(true);
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  const handleDeleteSession = async () => {
    try {
      if (selectedTeachingStudentId) {
        await dispatch(deleteTeachingSession(DetailsSession.session?.id)).unwrap();
        toast.success("séance d'enseignement supprimée avec succès!");
      } else if (selectedGroupId) {
        await dispatch(deleteSession(DetailsSession.session?.id)).unwrap();
        toast.success("séance de groupe supprimée avec succès!");
      } else {
        toast.error("Aucun type de séance sélectionné pour la suppression.");
        return;
      }

      setEventDetailsModalVisible(false);
      setEventDetailsModalIndividual(false);
      fetchCurrentSessions(
        currentWeekStart.format("YYYY-MM-DD"),
        currentWeekStart
          .clone()
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")
      );
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  const handleDeleteRepeating = async () => {
    try {
      await dispatch(deleteRepeating(DetailsSession.session?.repeating_id)).unwrap();
      toast.success("série de séances supprimée avec succès !");
      setEventDetailsModalVisible(false);
      setEventDetailsModalIndividual(false);
      fetchCurrentSessions(
        currentWeekStart.format("YYYY-MM-DD"),
        currentWeekStart
          .clone()
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")
      );
    } catch (error) {
      const status = error?.status || error?.response?.status;
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message);
          break;
      }
    }
  };

  const handleEventClick = (event) => {
    if (selectedTeachingStudentId) {
      dispatch(fetchOneTeachingSession(event.id)).then((action) => {
        if (fetchOneTeachingSession.fulfilled.match(action)) {
          setDetailsSession(action.payload);
          setSelectedEvent(event);
          setEventDetailsModalIndividual(true);
        } else {
        }
      });
    } else {
      // dispatch(fetchStudentsOfSession(event.id)).then((action) => {
      //   if (fetchStudentsOfSession.fulfilled.match(action)) {
      //     setStudents(action.payload);
      //   } else {
      //   }
      // });

      dispatch(oneSession(event.id)).then((action) => {
        if (oneSession.fulfilled.match(action)) {
          setDetailsSession(action.payload);
          setSelectedEvent(event);
          setEventDetailsModalVisible(true);
        } else {
        }
      });
    }
  };

  useEffect(() => {
    if (DetailsSession) {
      setTeacherAbsent(DetailsSession.session?.teacher_absent);
    }
  }, [DetailsSession]);

  const fetchCurrentSessions = async (start, end) => {
    if (!start || !end) return;
    let action;
    if (selectedGroupId) {
      action = await dispatch(
        fetchSessions({
          groupId: selectedGroupId,
          startDate: start,
          endDate: end,
        })
      );
    } else if (selectedTeachingStudentId) {
      action = await dispatch(
        fetchTeachingSessions({
          teachingId: selectedTeachingStudentId,
          startDate: start,
          endDate: end,
        })
      );
    } else {
      setEvents([]);
      return;
    }
    if (
      fetchSessions.fulfilled.match(action) ||
      fetchTeachingSessions.fulfilled.match(action)
    ) {
      const formattedEvents = action.payload.map((session) => ({
        start: new Date(session.start),
        end: new Date(session.end),
        
        id: session.id,
        title: `${session.teacher?.nom || "pas prof"}  ${session.teacher?.prenom  }`,
      }));
      setEvents(formattedEvents);
    } else {
      // console.error("Failed to fetch sessions:", action.error);
    }
  };

  const handleNavigate = (date) => {
    const start = moment.utc(date).startOf("week");
    setCurrentWeekStart(start);
    const end = start.clone().endOf("week").add(1, "days");
    fetchCurrentSessions(start.format("YYYY-MM-DD"), end.format("YYYY-MM-DD"));
  };

  const handleSelectionTypeChange = (e) => {
    setSelectionType(e.target.value);
    setEvents([]);
    setSelectedGroupId(null);
    setSelectedTeachingStudentId(null);
    setSelectedFormation(null);
  };


  useEffect(() => {
    const start = currentWeekStart.format("YYYY-MM-DD");
    const end = currentWeekStart
      .clone()
      .endOf("week")
      .add(1, "days")
      .format("YYYY-MM-DD");
    fetchCurrentSessions(start, end);
  }, [currentWeekStart, selectedGroupId, selectedTeachingStudentId, dispatch]);

  useEffect(() => {
    if (selectedGroupId && !groupDetails) {
      dispatch(fetchGroupById(selectedGroupId));
    }
  }, [selectedGroupId, dispatch, groupDetails]);
  
  useEffect(() => {
    if (selectedGroupId) {
      const group = groups.find(group => group.id === selectedGroupId);
      if (group) {
        setGroupDetails(group); 
      }
    }
  }, [selectedGroupId, groups]);
  

  useEffect(() => {
    const fetchTeachingStudent = async () => {
      if (selectedTeachingStudentId) {
        try {
          const action = await dispatch(
            fetchOneTeachingStudent(selectedTeachingStudentId)
          );
          if (fetchOneTeachingStudent.fulfilled.match(action)) {
            setDetailsTeaching(action.payload);
          } else {
          }
        } catch (error) {
        }
      }
    };

    fetchTeachingStudent();
  }, [selectedTeachingStudentId, dispatch]);

  const handleShowGroupDetails = () => {
    setShowGroupDetailsModal(true);
  };

  const handleShow = () => {
    setShowModal(true);
  };

  const handleClose = () => {
    setShowModal(false);
    setStartDate(null);
    setStartTime(null);
    setEndDate(null);
    setEndTime(null);
    setRepeatOption(null);
    setIntervalValue(1);
    setSelectedRoomId(null);
    setSkip(false);
    setSelectedDays([]);
    setIsRemote(true);
    setRepeated(false);
  };

  // date de start et end
  const onSelectSlot = useCallback((slotInfo) => {
    const start = slotInfo.start;
    const end = slotInfo.end;
    setStartDate(moment(start).format("YYYY-MM-DD"));
    setStartTime(moment(start).format("HH:mm"));
    setEndDate(moment(end).format("YYYY-MM-DD"));
    setEndTime(moment(end).format("HH:mm"));

    handleShow();
  }, []);

  const handleDayClick = (day) => {
    setSelectedDays((prevDays) =>
      prevDays.includes(day)
        ? prevDays.filter((d) => d !== day)
        : [...prevDays, day]
    );
  };

  const handleSaveRepetition = () => {
    handleSecondClose();
  };

  const handleRepeatOptionChange = (e) => {
    const value = e.target.value;
    setRepeatOption(value);
    setRepeated(value !== "");
    if (value === "Daily" || value === "Weekly") {
      setShowSecondModal(true);
    }
  };

  const handleSecondClose = () => {
    setShowSecondModal(false);
  };

  const handleRemote = () => {
    setIsRemote(!isRemote);
  };

  useEffect(() => {
    if(showModal){
      dispatch(fetchRooms());
    }
  }, [dispatch , showModal]);

  useEffect(() => {
    dispatch(fetchFormationsWithCategories({searchTerm }));
  }, [dispatch ,searchTerm ]);

  useEffect(() => {
    if (selectedFormation) {
      dispatch(
        fetchGroups({
          formation: selectedFormation.value,
          year_id: selectedYear,
        })
      );
    }
  }, [dispatch, selectedFormation, selectedYear]);

  const validateSessionData = () => {
    const errors = {};

    if (!selectedGroupId) {
      errors.group_id = "Le groupe est requise.";
    }
    if (groupDetails && groupDetails.status==="finish") {
      errors.status =
        "Impossible d'ajouter la séance : le groupe est terminé .";
    }
    if (!startDate) {
      errors.date_start = "La date de début est requise.";
    }
    if (!endDate) {
      errors.date_end = "La date de fin est requise.";
    }
    if (!startTime) {
      errors.start = "L'heure de début est requise.";
    }
    if (!endTime) {
      errors.end = "L'heure de fin est requise.";
    }
    if (intervalValue < 1) {
      errors.interval_value = "interval_value doit être un entier positif.";
    }

    if (repeatOption === "Weekly" && selectedDays.length === 0) {
      errors.selected_days =
        "Vous devez sélectionner au moins un jour pour une répétition hebdomadaire.";
    }

    if (isRemote && !selectedRoomId) {
      errors.room_id =
        "Une salle est requise pour les séances pas à distance .";
    }

    return errors;
  };

  const validateTeachingSessionData = () => {
    const errors = {};

    if (!selectedTeachingStudentId) {
      errors.teaching_id = "Un étudiant à enseigner est requise.";
    }
    if (detailsTeaching && detailsTeaching.status ==="finish") {
      errors.status =
        "Impossible d'ajouter la séance : la personne est terminé.";
    }
    if (!startDate) {
      errors.date_start = "La date de début est requise.";
    }
    if (!endDate) {
      errors.date_end = "La date de fin est requise.";
    }
    if (!startTime) {
      errors.start = "L'heure de début est requise.";
    }
    if (!endTime) {
      errors.end = "L'heure de fin est requise.";
    }

    if (intervalValue < 1) {
      errors.interval_value = "interval_value doit être un entier positif.";
    }

    if (repeatOption === "Weekly" && selectedDays.length === 0) {
      errors.selected_days =
        "Vous devez sélectionner au moins un jour pour une répétition hebdomadaire.";
    }

    if (
      isRemote &&
      !selectedRoomId &&
      detailsTeaching.choice?.teaching_method !== "At home"
    ) {
      errors.room_id = "Une salle est requise pour les séances pas à distance.";
    }

    return errors;
  };


  const handleSaveSession = async () => {
    setLoading(true);
    let validationErrors = {};


    if (selectionType === "group") {
      validationErrors = validateSessionData();
    } else if (selectionType === "individual") {
      validationErrors = validateTeachingSessionData();
    }

    if (Object.keys(validationErrors).length > 0) {
      Object.values(validationErrors).forEach((error) => toast.error(error));
      setLoading(false); 
      return;
    }

    if (selectionType === "group") {
      const selectedGroup = groups.find(
        (group) => group.id === selectedGroupId
      );
      if (!selectedGroup || !selectedGroup.teacher) {
        toast.error("Professeur non trouvé.");
        setLoading(false);
        return;
      }
    }

    const sessionData = {
      ...(selectionType === "group"
        ? { group_id: selectedGroupId }
        : { teaching_id: selectedTeachingStudentId }),
      date_start: startDate,
      start: startTime,
      end: endTime,
      date_end: endDate,
      repeated,
      skip,
      ...(repeated && {
        interval: repeatOption,
        interval_value: intervalValue,
      }),
      ...(repeatOption === "Weekly" && {
        days_of_week: selectedDays,
      }),
      ...(isRemote &&
        detailsTeaching.choice?.teaching_method !== "At home" && {
          room_id: selectedRoomId,
        }),
    };

    const action =
      selectionType === "group"
        ? addSession(sessionData)
        : addTeachingSession(sessionData);

    try {
     await dispatch(action).unwrap();
      await fetchCurrentSessions(
        currentWeekStart.format("YYYY-MM-DD"),
        currentWeekStart
          .clone()
          .endOf("week")
          .add(1, "days")
          .format("YYYY-MM-DD")
      );

      handleClose();
      toast.success("Session ajoutée avec succès!");
    } catch (error) {

      const status = error?.status || error?.response?.status || 500; // Default to 500 if not defined
      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        case 400:
          toast.error(
            "Vous ne pouvez pas ajouter une séance dans cette salle à ce moment car elle est réservée pour un autre groupe."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
    finally {
      setLoading(false);
    }
  };
  
  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };


  return (
    <div className="Calendarier">
      <div className="all-selection">
        <div className="select-name">
          <Form.Select
            aria-label="Default select example"
            value={selectionType}
            onChange={handleSelectionTypeChange}
          >
            <option value="group" selected>
              Groupe
            </option>
            <option value="individual">Étudiant</option>
          </Form.Select>
        </div>
        <div className="formation-group">
          <Select
            options={formationsWithCategories?.map((formation) => ({
              value: formation.id,
              label: `${capitalizeFirstLetter(formation?.name)}`,
              category: formation.categorie?.name,
            }))}
            value={selectedFormation}
            onChange={(selectedOption) => {
              setSelectedFormation(selectedOption);
            }}
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            inputValue={searchTerm}
            getOptionLabel={(option) => (
              <div>
                <span style={{fontSize: "1em"}}>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.category && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeFirstLetter(option.category)}
                  </span>
                )}
              </div>
            )}
            filterOption={(candidate, input) =>
              candidate.data.label.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="Par Formation"
          />
        </div>
        {selectionType === "group" ? (
          <>
            <div className="group-details">
              <GroupSelector
                selectedFormation={selectedFormation}
                groups={groups}
                selectedGroupId={selectedGroupId}
                setSelectedGroupId={setSelectedGroupId}
                handleShowGroupDetails={handleShowGroupDetails}
              />
            </div>
          </>
        ) : (
          <>
            {/* name individual */}
            {selectedFormation && (
              <div className="name-individual">
                <Select
                  aria-label="Sélectionner étudiant"
                  // value={
                  //   teachingStudents.length > 0 &&
                  //   teachingStudents.some(
                  //     (t) => t.id === selectedTeachingStudentId
                  //   )
                  //     ? {
                  //         value: selectedTeachingStudentId,
                  //         label: `${
                  //           teachingStudents.find(
                  //             (t) => t.id === selectedTeachingStudentId
                  //           )?.student?.nom
                  //         } ${
                  //           teachingStudents.find(
                  //             (t) => t.id === selectedTeachingStudentId
                  //           )?.student?.prenom
                  //         }`,
                  //       }
                  //     : null
                  // }
                  onChange={(selectedOption) =>
                    setSelectedTeachingStudentId(
                      selectedOption ? selectedOption.value : ""
                    )
                  }
                  options={teachingStudents.map((t) => ({
                    value: t.id,
                    label: `${t.student?.nom} ${t.student?.prenom}`,
                    level :t.level?.name
                  }))}

                  getOptionLabel={(option) => (
                    <div>
                      <span>
                        {capitalizeFirstLetter(option.label)}
                      </span>
                      {option.level && (
                        <span
                          style={{
                            fontSize: "0.8em",
                            color: "gray",
                            marginLeft: "5px",
                            fontStyle: "italic",
                          }}
                        >
                          {option?.level?.toUpperCase()}
                        </span>
                      )}
                    </div>
                  )}
                  placeholder="Sélectionner étudiant"
                />
              </div>
            )}
            {/* details individual  */}
            <div className="details-individual">
              {selectedTeachingStudentId && (
                <Button
                  onClick={() =>
                    handleShowTeachingDetails(selectedTeachingStudentId)
                  }
                >
                  Détails
                </Button>
              )}
            </div>
          </>
        )}
        {/* button nouvelle seance */}
        <div className="nouvelle-seance">
          <Button
            variant="primary"
            onClick={handleShow}
            style={{ border: "none" }}
          >
            <FaPlus /> Séance
          </Button>
        </div>
      </div>

      <div className="hieght600">
        <Calendar
          localizer={localizer}
          events={events}
          style={{ height: "calc(100vh - 119px)", marginTop: "5px" }}
          views={{ day: true, week: true }}
          defaultView="week"
          onSelectSlot={onSelectSlot}
          onSelectEvent={handleEventClick}
          selectable
          onNavigate={handleNavigate}
          messages={{
            today: "Aujourd'hui",
            previous: "Précédent",
            next: "Suivant",
            month: "Mois",
            week: "Semaine",
            day: "Jour",
          }}
        />
      </div>

      {/* modal ajoute session */}
      <AddSessionModal
        showModal={showModal}
        handleClose={handleClose}
        startDate={startDate}
        setStartDate={setStartDate}
        startTime={startTime}
        setStartTime={setStartTime}
        endDate={endDate}
        setEndDate={setEndDate}
        endTime={endTime}
        setEndTime={setEndTime}
        repeatOption={repeatOption}
        setRepeatOption={setRepeatOption}
        isRemote={isRemote}
        setIsRemote={setIsRemote}
        selectedRoomId={selectedRoomId}
        setSelectedRoomId={setSelectedRoomId}
        handleSaveSession={handleSaveSession}
        rooms={rooms}
        handleRemote={handleRemote}
        handleRepeatOptionChange={handleRepeatOptionChange}
        skip={skip}
        setSkip={setSkip}
        detailsTeaching={detailsTeaching}
        groupDetails={groupDetails}
        loading={loading}
      />

      {/* modal de repetation  */}
      <RepeatSessionModal
        showSecondModal={showSecondModal}
        handleSecondClose={handleSecondClose}
        startDate={startDate}
        setStartDate={setStartDate}
        intervalValue={intervalValue}
        setIntervalValue={setIntervalValue}
        repeatOption={repeatOption}
        setRepeatOption={setRepeatOption}
        selectedDays={selectedDays}
        handleDayClick={handleDayClick}
        endDate={endDate}
        setEndDate={setEndDate}
        handleSaveRepetition={handleSaveRepetition}
        handleRepeatOptionChange={handleRepeatOptionChange}
        skip={skip}
        setSkip={setSkip}
      />

      {/* Modal de group details */}
      <GroupDetailsModal
        showGroupDetailsModal={showGroupDetailsModal}
        handleCloseGroupDetails={() => setShowGroupDetailsModal(false)}
        groupDetails={groupDetails}
      />

      {/*  modal de details seance et student groupe... */}
      <EventDetailsModal
        show={eventDetailsModalVisible}
        handleClose={() => setEventDetailsModalVisible(false)}
        event={selectedEvent}
        setDetailsSession={setDetailsSession}
        DetailsSession={DetailsSession}
        handleCheckboxChangeProf={handleCheckboxChangeProf}
        handleDeleteSession={handleDeleteSession}
        handleDeleteRepeating={handleDeleteRepeating}
        teacherAbsent={teacherAbsent}
      />

      <EventDetailsIndividual
        show={eventDetailsModalIndividual}
        setEventDetailsModalIndividual={setEventDetailsModalIndividual}
        event={selectedEvent}
        students={students}
        DetailsSession={DetailsSession}
        handleCheckboxChangeProf={handleCheckboxChangeProf}
        handleDeleteSession={handleDeleteSession}
        handleDeleteRepeating={handleDeleteRepeating}
        teacherAbsent={teacherAbsent}
      />
      <EventDetailsTeaching
        show={showTeachingDetailsModal}
        handleClose={() => setShowTeachingDetailsModal(false)}
        detailsTeaching={detailsTeaching}
      />
    </div>
  );
};

export default Calendarier;
