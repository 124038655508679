import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import axiosPrivate from '../../api/axios';


export const updateCenter = createAsyncThunk(
  'center/updateCenter',
  async ({fee, payment_type, specific_day }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post(`/centers`, { fee, payment_type, specific_day });
      console.log("res",response);
      return response;
    } catch (err) {
      return rejectWithValue(err.response.data);
    }
  }
);

export const centerProfits = createAsyncThunk(
  'centers/centerProfits',
  async({year_id},{rejectWithValue})=>{
    try{
      const res= await axiosPrivate.get(`/centers/profits/${year_id}`);
      return res.data;
    }
    catch(error){
      return rejectWithValue(error.response);
    }
  }
)

const centerSlice = createSlice({
  name: 'center',
  initialState: {
    center: [],
    loading: false,
    error: null,
  },
  reducers: {
  },
  extraReducers: (builder) => {
    builder
      .addCase(updateCenter.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateCenter.fulfilled, (state, action) => {
        state.loading = false;
        state.center = action.payload;
        console.log("Updated center data:", action.payload);
      })
      .addCase(updateCenter.rejected, (state, action) => {
        state.loading = false;
        state.error = action.payload;
      })
      .addCase(centerProfits.pending , (state)=>{
        state.loading = true;
        state.error = null;
      })
      .addCase(centerProfits.fulfilled , (state,action)=>{
        state.loading = false;
        state.center = action.payload;
      })
      .addCase(centerProfits.rejected , (state,action)=>{
        state.loading = false;
        state.error = action.payload;
      })
  },
});

export default centerSlice.reducer;
