import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { updateCenter } from "../../Redux/centers/centerSlice";
import { toast } from "react-toastify";
import { updateFeeAndSpecificDay } from "../../Redux/Auth/authSlice";
import "./Config.css";
import { Button, Container } from "react-bootstrap";

export const Config = () => {
  const dispatch = useDispatch();

  const { auth } = useSelector((e) => e);

  const [fee, setFee] = useState(auth.user?.fee || null);
  const [frais, setFrais] = useState(auth.user?.fee ? "oui" : "null");
  const [paymentType, setPaymentType] = useState(auth.user?.payment_type);
  const [specificDay, setSpecificDay] = useState(auth.user?.specific_day);
  const [isSpecificDaySelected, setIsSpecificDaySelected] = useState(
    auth.user?.payment_type
  );
  const [isEditing, setIsEditing] = useState(false);

  console.log("paymentType", paymentType);

  useEffect(() => {
    if (auth) {
      setFee(auth.user.fee);
      setFrais(auth.user.fee ? "oui" : "null");
      setPaymentType(auth.user.payment_type);
      setSpecificDay(auth.user.specific_day || "");
      setIsSpecificDaySelected(auth.user.payment_type);
    }
  }, [auth]);

  const handleFeeChange = (event) => {
    const value = event.target.value;
    setFrais(value);

    if (value === "null") {
      setFee(null);
    }
  };

  const handlePaymentTypeChange = (event) => {
    setPaymentType(event.target.value);
    setIsSpecificDaySelected(event.target.value);
  };

  const handleSpecificDayChange = (event) => {
    const value = event.target.value;
      setSpecificDay(value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      dispatch(
        updateFeeAndSpecificDay({
          fee: fee,
          payment_type: paymentType,
          specific_day: paymentType === "specific_day" ? specificDay : null,
        })
      );

      await dispatch(
        updateCenter({
          fee,
          payment_type: paymentType,
          specific_day: paymentType === "specific_day" ? specificDay : null,
        })
      ).unwrap();

      toast.success("Centre mis à jour avec succès !");
      setIsEditing(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du centre:", error);
      toast.error(
        "Une erreur est survenue lors de la mise à jour. Veuillez réessayer."
      );
    }
  };

  return (
    <div>
      <h2 className="text-center">Configuration Centre</h2>

      {isEditing === false && (
        <Container className="containers">
          <div className="card text-center mx-auto custom-card">
            <div className="card-body">
              <h5 className="card-title">Détails d'inscription</h5>
              <p className="card-text">
                Montant d'inscription étudiant :
                {auth.user?.fee != null
                  ? ` ${auth.user?.fee} Dh`
                  : " Aucune inscription"}
              </p>
              <p className="card-text">
                Le jour spécifique pour le paiement des élèves :
                <span>
                  {auth.user?.specific_day
                    ? `Dans ${auth.user?.specific_day} chaque mois`
                    : " par jour de étudiant inscrit"}
                </span>
              </p>

              {!isEditing && (
                <Button
                  className="btn btn-primary"
                  size="sm"
                  onClick={() => setIsEditing(true)}
                >
                  Mettre à jour
                </Button>
              )}
            </div>
          </div>
        </Container>
      )}

      {isEditing && (
        <Container className="containers">
          <form onSubmit={handleSubmit}>
            <div className="toute">
              <div className="form-group">
                <label>Y a-t-il des frais d'inscription ?</label>
                <div className="radio-group">
                  <input
                    type="radio"
                    value="oui"
                    checked={frais === "oui"}
                    onChange={handleFeeChange}
                  />
                  <label>oui</label>
                </div>
                <div className="radio-group">
                  <input
                    type="radio"
                    value="null"
                    checked={frais === "null"}
                    onChange={handleFeeChange}
                  />
                  <label>non</label>
                </div>
              </div>
              {frais === "oui" && (
                <input
                  type="number"
                  value={auth.user?.fee}
                  onChange={(e) => setFee(e.target.value)}
                  placeholder="Montant des frais d'inscription"
                />
              )}
            </div>

            <div className="toute">
              <div className="form-group">
                <label>
                  Mode de paiement :
                </label>
                <select
                  name="payment_type"
                  value={paymentType}
                  onChange={handlePaymentTypeChange}
                  style={{width:"50%"}}
                >
                  <option value="according_student">Chaque mois dés la premiére séance</option>
                  <option value="specific_day">Un jour spécifique</option>
                </select>
              </div>

              {isSpecificDaySelected === "specific_day" && (
                <div>
                  <input
                    type="number"
                    value={specificDay}
                    onChange={handleSpecificDayChange}
                    placeholder="Choisissez le jour (1-31)"
                    min="1"
                    max="31"
                  />
                </div>
              )}
            </div>
            <div className="text-center">
              <Button size="sm" type="submit" className="btn btn-primary">
                Envoyer
              </Button>
            </div>
          </form>
        </Container>
      )}
    </div>
  );
};
