import React, { useEffect, useState } from "react";
import { Modal, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { fetchFormationsWithCategories } from "../../Redux/formation/formationSlice";
import { determineChoice } from "../../Redux/choix/teachingChoiceSlice";
import { fetchGroups } from "../../Redux/groupe/Group";
import "./Modalchoix.css";
import { fetchNextThreeSessions } from "../../Redux/groupSessions/groupSessions";
import { addStudent } from "../../Redux/groupStudents/GroupStudentsSlice";
import { fetchLevelsByFormation } from "../../Redux/levels/levelsSlice";
import { fetchProfessors } from "../../Redux/Profs/professorsSlice";
import { addTeachingStudent } from "../../Redux/teachingStudents/teachingStudentSlice";
import Select from "react-select";
import { useDebounce } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { updateStudentStatusInState } from "../../Redux/Etudent/studentsSlice";

const Modalchoix = ({ show, studentId, setShowModalChoix, selectedYear }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const [activeButton, setActiveButton] = useState("");
  const [selectedFormationId, setSelectedFormationId] = useState("");
  const [selectedLevelId, setSelectedLevelId] = useState("");
  const [selectedTeacherId, setSelectedTeacherId] = useState("");
  const [selectedGroupId, setSelectedGroupId] = useState("");
  const [nextThreeSessions, setNextThreeSessions] = useState([]);
  const [selectedSession, setSelectedSession] = useState(null);
  const [levels, setLevels] = useState([]);
  const [stockTeaching, setStockTeaching] = useState([]);
  const [selectedTeachingMethod, setSelectedTeachingMethod] = useState(null);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const formationsWithCategories = useSelector(
    (state) => state.formations.formationsWithCategories
  );
  const groups = useSelector((state) => state.groups.groups);
  const { professors } = useSelector((state) => state.professors);
  const [professorSearchTerm, setProfessorSearchTerm] = useState("");
  const [debouncedSearchTerm] = useDebounce(professorSearchTerm, 600);
  const [searchTerm, setSearchTerm] = useState("");
  const [debouncedSearchfrom] = useDebounce(searchTerm, 600);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (currentPage === 2 || currentPage === 3) {
          dispatch(fetchProfessors({ searchTerm: debouncedSearchTerm }));
        }
        if (currentPage === 1 || currentPage === 2 || currentPage === 3) {
          await dispatch(
            fetchFormationsWithCategories({ searchTerm: debouncedSearchfrom })
          );
        }
      } catch (error) {
        if (error.response) {
          const { status } = error.response;
          switch (status) {
            case 403:
              navigate("/Forbidden");
              break;
            case 404:
              navigate("/not-found");
              break;
            case 401:
              navigate("/Unauthorized");
              break;
            case 500:
              navigate("/internal-error");
              break;
            case 402:
              toast.error(
                "Abonnement terminé. Veuillez renouveler votre abonnement."
              );
              break;
            default:
              toast.error(error.message);
              break;
          }
        } else {
          toast.error(error.message);
        }
      }
    };

    fetchData();
  }, [
    dispatch,
    currentPage,
    debouncedSearchTerm,
    navigate,
    debouncedSearchfrom,
  ]);

  useEffect(() => {
    try {
      if (selectedFormationId) {
        if (currentPage === 2 || currentPage === 3) {
          dispatch(
            determineChoice({
              formationId: selectedFormationId,
              levelId: selectedLevelId,
            })
          ).then((response) => {
            setStockTeaching(response.payload);
          });
          dispatch(fetchLevelsByFormation(selectedFormationId)).then((levels) => {
            setLevels(levels.payload);
          });
        }
        dispatch(
          fetchGroups({ formation: selectedFormationId, year_id: selectedYear })
        );
      }
    } catch (error) {
      if (error.response) {
        const { status } = error.response;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
    }
  }, [
    selectedFormationId,
    dispatch,
    selectedLevelId,
    navigate,
    selectedYear,
    currentPage,
  ]);

  const handleFormationChange = (selectedOption) => {
    const formationId = selectedOption?.value;
    setSelectedFormationId(formationId);
  };

  const handleLevelChange = (selectedOption) => {
    if (selectedOption) {
      const levelId = selectedOption.value;
      setSelectedLevelId(levelId);
    }
  };

  const handleTeacherChange = (selectedOption) => {
    if (selectedOption) {
      const teacherId = selectedOption.value;
      setSelectedTeacherId(teacherId);
    }
  };

  const handleGroupChange = (selectedOption) => {
    const groupId = selectedOption?.value;
    setSelectedGroupId(groupId);
    dispatch(fetchNextThreeSessions(groupId)).then((response) => {
      setNextThreeSessions(response.payload);
    });
  };

  const handleButtonClick = (buttonText) => {
    setActiveButton(buttonText);
    switch (buttonText) {
      case "À Domicile":
        setCurrentPage(3);
        break;
      case "Individuel":
        setCurrentPage(2);
        break;
      case "Groupe":
        setCurrentPage(1);
        break;
      default:
        break;
    }
  };

  const validateInputs = () => {
    let errorMessage = "";

    // Validation for "Group"
    if (activeButton === "Groupe") {
      if (!selectedGroupId) {
        errorMessage = "Le groupe est requise.";
      }
      if (!selectedFormationId) {
        errorMessage = "Formation est requise.";
      }
    }
    // Validation for "Individual" and "At Home"
    else if (activeButton === "Individuel" || activeButton === "À Domicile") {
      if (!selectedFormationId) {
        errorMessage = "Formation est requise.";
      }
      if (!selectedTeacherId) {
        errorMessage = "proffeseur est requise.";
      }
      if (!selectedTeachingMethod) {
        errorMessage = "Le choix d'enseignement est requise.";
      }
    }

    return errorMessage;
  };

  // function close modal
  const handelColsemodal = () => {
    setShowModalChoix(false);
    setCurrentPage(0);
    setActiveButton("");
    setSelectedFormationId("");
    setSelectedGroupId("");
    setSelectedLevelId("");
    setSelectedSession(null);
    setNextThreeSessions([]);
    setLevels([]);
    setStockTeaching([]);
  };

  const handleAddStudent = async () => {
    try {
      const errorMessage = validateInputs();

      if (errorMessage) {
        toast.error(errorMessage);
        return;
      }
      if (activeButton === "Groupe") {
        if (selectedGroupId) {
          const studentData = {
            group_id: selectedGroupId,
            student_id: studentId,
            ...(selectedSession && { first_session: selectedSession }),
          };
          await dispatch(addStudent(studentData)).unwrap();
          dispatch(
            updateStudentStatusInState({
              id: studentId,
              newStatus: "confirmed",
            })
          );
          toast.success("Étudiant ajouté au groupe avec succès!");
          handelColsemodal();
        }
      } else if (activeButton === "Individuel") {
        const studentData = {
          student_id: studentId,
          formation_id: selectedFormationId,
          year_id: selectedYear,
          choice_id: selectedTeachingMethod,
          teacher_id: selectedTeacherId,
          ...(selectedLevelId && { level_id: selectedLevelId }),
        };
        await dispatch(addTeachingStudent(studentData)).unwrap();
        dispatch(
          updateStudentStatusInState({ id: studentId, newStatus: "confirmed" })
        );
        toast.success("Étudiant ajouté avec succès!");
        handelColsemodal();
      } else if (activeButton === "À Domicile") {
        const studentData = {
          student_id: studentId,
          formation_id: selectedFormationId,
          year_id: selectedYear,
          choice_id: selectedTeachingMethod,
          teacher_id: selectedTeacherId,
          ...(selectedLevelId && { level_id: selectedLevelId }),
        };
        await dispatch(addTeachingStudent(studentData)).unwrap();
        dispatch(
          updateStudentStatusInState({ id: studentId, newStatus: "confirmed" })
        );
        toast.success("Étudiant ajouté avec succès!");
        handelColsemodal();
      }
    } catch (error) {
      if (error) {
        const { status } = error;
        const msg = error.data?.msg;

        if (msg === "Group finish") {
          toast.error("Groupe terminé.");
        } else {
          switch (status) {
            case 403:
              navigate("/Forbidden");
              break;
            case 404:
              navigate("/not-found");
              break;
            case 401:
              navigate("/Unauthorized");
              break;
            case 500:
              navigate("/internal-error");
              break;
            case 402:
              toast.error(
                "Abonnement terminé. Veuillez renouveler votre abonnement."
              );
              break;
            case 400:
              toast.error("Étudiant déjà dans le groupe");
              break;
            default:
              toast.error(error.message);
              break;
          }
        }
      } else {
        toast.error(error.message);
      }
    }
  };

  // change teaching methode a null if change formation ou level
  useEffect(() => {
    setSelectedTeachingMethod(null);
  }, [selectedFormationId, selectedLevelId]);

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  const pages = [
    <div key="page1" className="page1">
      <div className="group-btn">
        <div
          className={`btn-choix ${
            activeButton === "À Domicile" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("À Domicile")}
        >
          À Domicile
        </div>
        <div
          className={`btn-choix ${activeButton === "Groupe" ? "active" : ""}`}
          onClick={() => handleButtonClick("Groupe")}
        >
          Groupe
        </div>
        <div
          className={`btn-choix ${
            activeButton === "Individuel" ? "active" : ""
          }`}
          onClick={() => handleButtonClick("Individuel")}
        >
          Individuel
        </div>
      </div>
    </div>,
    <div key="page2" className="page2">
      <Form>
        <Form.Group className="mb-3">
          <Select
            options={formationsWithCategories?.map((formation) => ({
              value: formation.id,
              label: `${capitalizeFirstLetter(formation?.name)}`,
              category: formation.categorie?.name,
            }))}
            onChange={handleFormationChange}
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            inputValue={searchTerm}
            getOptionLabel={(option) => (
              <div>
                <span style={{ fontSize: "1em" }}>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.category && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeFirstLetter(option.category)}
                  </span>
                )}
              </div>
            )}
            filterOption={(candidate, input) =>
              candidate.data.label.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="sélectionner Formation"
          />
        </Form.Group>
        <Form.Group className="mb-3">
          <Select
            options={groups.map((g) => ({
              value: g.id,
              label: capitalizeFirstLetter(g.name),
            }))}
            placeholder="sélectionner groupe"
            onChange={handleGroupChange}
          />
        </Form.Group>
        <div className="tickets">
          <h5>3 premiere seance :</h5>
          <ul className="groupSeance mt-4">
            {nextThreeSessions.map((session) => (
              <div
                key={session.id}
                className={`seance ${
                  selectedSession === session.id ? "active" : ""
                }`}
                onClick={() => {
                  setSelectedSession(session.id);
                }}
              >
                <div className="date">
                  {new Date(session.start).toLocaleDateString()}
                </div>
                <div className="day">
                  {new Date(session.start).toLocaleString("fr-FR", {
                    weekday: "long",
                  })}
                </div>
                <div className="time">
                  {new Date(session.start).toLocaleTimeString([], {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                  })}
                </div>
              </div>
            ))}
          </ul>
        </div>
      </Form>
    </div>,
    <div key="page2-x" className="page2-x">
      <Form>
        <Form.Group className="mb-3">
          <Select
            options={formationsWithCategories?.map((formation) => ({
              value: formation.id,
              label: `${capitalizeFirstLetter(formation?.name)}`,
              category: formation.categorie?.name,
            }))}
            onChange={handleFormationChange}
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            inputValue={searchTerm}
            getOptionLabel={(option) => (
              <div>
                <span style={{ fontSize: "1em" }}>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.category && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeFirstLetter(option.category)}
                  </span>
                )}
              </div>
            )}
            filterOption={(candidate, input) =>
              candidate.data.label.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="sélectionner Formation"
          />
        </Form.Group>

        {levels && levels.length > 0 && (
          <Form.Group className="mb-3">
            <Select
              options={levels.map((l) => ({
                value: l.id,
                label: l?.name?.toUpperCase(),
              }))}
              placeholder="sélectionner Niveau"
              onChange={handleLevelChange}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Select
            options={professors?.map((p) => ({
              value: p.id,
              label: capitalizeFirstLetter(p.nom +" "+ p.prenom),
            }))}
            placeholder="sélectionner prof"
            onChange={handleTeacherChange}
            onInputChange={(inputValue) => setProfessorSearchTerm(inputValue)}
            inputValue={professorSearchTerm} // Controlled input for search
          />
        </Form.Group>

        <div className="tickets">
          <h5>Choisir l'enseignement : </h5>
          {stockTeaching.length > 0 ? (
            <ul>
              {stockTeaching
                .filter((choice) => choice.teaching_method === "Individual")
                .map((choice) => (
                  <div
                    key={choice.id}
                    onClick={() => {
                      if (selectedTeachingMethod === choice.id) {
                        setSelectedTeachingMethod(null);
                      } else {
                        setSelectedTeachingMethod(choice.id);
                      }
                    }}
                    className={`teaching-choice ${
                      selectedTeachingMethod === choice.id
                        ? "selectedteaching"
                        : "not-selected"
                    }`}
                  >
                    <p className="price-modal">{choice.price} dh</p>
                    <div className="teaching-modal">
                      <p>{choice.session_duration} H / Séance</p>
                      <p>{choice.session_nb} Séance / Semaine</p>
                      <p>
                        {choice?.formation_duration === 0
                          ? "Par mois"
                          : choice?.formation_duration + " Mois"}
                      </p>
                    </div>
                  </div>
                ))}
            </ul>
          ) : (
            <div style={{ textAlign: "center" }}>Choix non trouvé</div>
          )}
        </div>
      </Form>
    </div>,
    <div key="page3" className="page3">
      <Form>
        <Form.Group className="mb-3">
          <Select
            options={formationsWithCategories?.map((formation) => ({
              value: formation.id,
              label: `${capitalizeFirstLetter(formation?.name)}`,
              category: formation.categorie?.name,
            }))}
            onChange={handleFormationChange}
            onInputChange={(inputValue) => setSearchTerm(inputValue)}
            inputValue={searchTerm}
            getOptionLabel={(option) => (
              <div>
                <span style={{ fontSize: "1em" }}>
                  {capitalizeFirstLetter(option.label)}
                </span>
                {option.category && (
                  <span
                    style={{
                      fontSize: "0.8em",
                      color: "gray",
                      marginLeft: "5px",
                      fontStyle: "italic",
                    }}
                  >
                    {capitalizeFirstLetter(option.category)}
                  </span>
                )}
              </div>
            )}
            filterOption={(candidate, input) =>
              candidate.data.label.toLowerCase().includes(input.toLowerCase())
            }
            placeholder="sélectionner Formation"
          />
        </Form.Group>

        {levels && levels.length > 0 && (
          <Form.Group className="mb-3">
            <Select
              options={levels.map((l) => ({
                value: l.id,
                label: l?.name?.toUpperCase(),
              }))}
              placeholder="sélectionner Niveau"
              onChange={handleLevelChange}
            />
          </Form.Group>
        )}

        <Form.Group className="mb-3">
          <Select
            options={professors?.map((p) => ({
              value: p.id,
              label: capitalizeFirstLetter(p.nom +" "+ p.prenom),
            }))}
            placeholder="sélectionner prof"
            onChange={handleTeacherChange}
            onInputChange={(inputValue) => setProfessorSearchTerm(inputValue)}
            inputValue={professorSearchTerm} // Controlled input for search
          />
        </Form.Group>
        <div className="tickets">
          <h5>Choisir l'enseignement :</h5>
          {stockTeaching.length > 0 ? (
            // <ul className="all-teaching-modal">
            <ul>
              {stockTeaching
                .filter((choice) => choice.teaching_method === "At home")
                .map((choice) => (
                  <div
                    key={choice.id}
                    onClick={() => {
                      if (selectedTeachingMethod === choice.id) {
                        setSelectedTeachingMethod(null);
                      } else {
                        setSelectedTeachingMethod(choice.id);
                      }
                    }}
                    className={`teaching-choice ${
                      selectedTeachingMethod === choice.id
                        ? "selectedteaching"
                        : "not-selected"
                    }`}
                  >
                    <p className="price-modal">{choice.price} dh</p>
                    <div className="teaching-modal">
                      <p>{choice.session_duration} H / Séance</p>
                      <p>{choice.session_nb} Séance / Semaine</p>
                      <p>
                        {choice?.formation_duration === 0
                          ? "Par mois"
                          : choice?.formation_duration + " Mois"}
                      </p>
                    </div>
                  </div>
                ))}
            </ul>
          ) : (
            <div style={{ textAlign: "center" }}>Choix non trouvé</div>
          )}
        </div>
      </Form>
    </div>,
  ];

  const handlePrevious = () => {
    setCurrentPage(0);
    setStockTeaching([]);
    setActiveButton("");
    setSelectedFormationId("");
    setSelectedGroupId("");
    setSelectedLevelId("");
    setSelectedSession(null);
    setNextThreeSessions([]);
    setLevels([]);
    setStockTeaching([]);
  };

  return (
    <>
      <Modal show={show} onHide={handelColsemodal}>
        <Modal.Header closeButton>
          <Modal.Title>Affecté l'étudiant {activeButton}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{pages[currentPage]}</Modal.Body>
        {currentPage > 0 && (
          <Modal.Footer>
            <Button variant="secondary" onClick={handlePrevious}>
              Précédent
            </Button>
            <Button variant="primary" onClick={handleAddStudent}>
              Ajouter
            </Button>
          </Modal.Footer>
        )}
      </Modal>
    </>
  );
};

export default Modalchoix;
