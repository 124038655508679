import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  addRoom,
  updateRoom,
  deleteRoom,
  clearStoreRooms,
} from "../../Redux/room/roomsSlice";
import {
  addCategory,
  updateCategory,
  deleteCategory,
  clearStoreCategorie,
} from "../../Redux/categories/categoriesSlice";
import {
  fetchLevels,
  addLevel,
  updateLevel,
  deleteLevel,
  resetLevels,
} from "../../Redux/levels/levelsSlice";
import {
  Card,
  Row,
  Col,
  Table,
  Modal,
  Button,
  Form,
  Spinner,
} from "react-bootstrap";
import {
  FaChalkboardTeacher,
  FaDoorClosed,
  FaList,
  FaPlus,
  FaUser,
  FaTrash,
  FaEdit,
} from "react-icons/fa";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./Home.css";
import { fetchDashboardData } from "../../Redux/Dashboard/dashboardSlice";
import { useNavigate } from "react-router-dom";
import { centerProfits } from "../../Redux/centers/centerSlice";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from "chart.js";
ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const Home = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { auth } = useSelector((e) => e);
  const levels = useSelector((state) => state.levels.levels);
  const [showModal, setShowModal] = useState(false);
  const [modalContent, setModalContent] = useState("");
  const [editEntity, setEditEntity] = useState(null);
  const [roomName, setRoomName] = useState("");
  const [categoryName, setCategoryName] = useState("");
  const [levelName, setLevelName] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectcategorylevel, setSelectcategorylevel] = useState("");
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteEntity, setDeleteEntity] = useState(null);
  const [deleteEntityType, setDeleteEntityType] = useState(""); // 'room', 'category', 'level', 'formation'
  const { selectedYear } = useSelector((state) => state.schoolYears);
  const { studentsCount, formationsCount, teachersCount, rooms, categories } =
    useSelector((state) => state.dashboard);

  const [isLoadingRoom, setIsLoadingRoom] = useState(false);
  const [isLoadingC, setIsLoadingC] = useState(false);
  const [isLoadingN, setIsLoadingN] = useState(false);

  // -------------------------------------------------------------graph------------------------------------------------------
  const center = useSelector((state) => state.center.center);
  const [chartData, setChartData] = useState({
    labels: [],
    datasets: [
      {
        label: "Total paiement",
        data: [],
        fill: false,
        borderColor: "rgba(75,192,192,1)",
        tension: 0.1,
      },
    ],
  });

  useEffect(() => {
    if (selectedYear) {
      dispatch(centerProfits({ year_id: selectedYear }));
    }
  }, [dispatch, selectedYear]);

  useEffect(() => {
    if (center.length > 0) {
      const labels = center.map((data) => data.month);
      const dataValues = center.map((data) => data.total_payment);
      setChartData({
        labels,
        datasets: [
          {
            label: "Total paiement",
            data: dataValues,
            fill: false,
            borderColor: "rgba(75,192,192,1)",
            tension: 0.1,
          },
        ],
      });
    }
  }, [center]);

  // -------------------------------------------------------------graph------------------------------------------------------

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (selectedYear) {
          await dispatch(fetchDashboardData(selectedYear)).unwrap();
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            navigate("/Forbidden");
          } else if (error.response.status === 404) {
            navigate("/not-found");
          } else if (error.response.status === 401) {
            navigate("/Unauthorized");
          } else if (error.response.status === 500) {
            navigate("/internal-error");
          } else if (error.response.status === 402) {
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
          }
        }
      }
    };
    fetchData();
  }, [dispatch, selectedYear, navigate]);

  useEffect(() => {
    if (selectcategorylevel) {
      dispatch(fetchLevels(selectcategorylevel));
    }
  }, [selectcategorylevel, dispatch]);

  const handleShowModal = (content, entity = null) => {
    setModalContent(content);
    setEditEntity(entity);
    if (entity) {
      if (content === "edit room") setRoomName(entity.name);
      if (content === "edit category") setCategoryName(entity.name);
      if (content === "edit level") {
        setLevelName(entity.name);
        setSelectedCategory(entity.categorie_id);
      }
    }
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setModalContent("");
    setRoomName("");
    setCategoryName("");
    setLevelName("");
    setSelectedCategory("");
    setEditEntity(null);
  };

  const handleRoomNameChange = (e) => setRoomName(e.target.value);
  const handleCategoryNameChange = (e) => setCategoryName(e.target.value);
  const handleLevelNameChange = (e) => setLevelName(e.target.value);
  const handleCategoryChange = (e) => setSelectedCategory(e.target.value);
  const handleCategorylevelChange = (e) =>
    setSelectcategorylevel(e.target.value);

  const handleSubmit = async () => {
    try {
      if (
        modalContent === "Ajoute une nouvelle salle" ||
        modalContent === "edit room"
      ) {
        setIsLoadingRoom(true);
      } else if (
        modalContent === "Ajoute une nouvelle Categorie" ||
        modalContent === "edit category"
      ) {
        setIsLoadingC(true);
      } else if (
        modalContent === "Ajoute un nouvelle niveau" ||
        modalContent === "edit level"
      ) {
        setIsLoadingN(true);
      }

      // Validate inputs
      if (modalContent === "Ajoute une nouvelle salle") {
        if (!roomName) {
          toast.error("Le nom de la salle est obligatoire");
          return;
        }
        await dispatch(addRoom({ name: roomName.toLowerCase() })).unwrap();
        toast.success("Salle ajoutée avec succès");
      } else if (modalContent === "edit room") {
        if (!roomName) {
          toast.error("Le nom de la salle est obligatoire");
          return;
        }
        await dispatch(
          updateRoom({ id: editEntity.id, name: roomName.toLowerCase() })
        ).unwrap();
        toast.success("Salle mise à jour avec succès");
      } else if (modalContent === "Ajoute une nouvelle Categorie") {
        if (!categoryName) {
          toast.error("Le nom de la catégorie est requise");
          return;
        }
        await dispatch(
          addCategory({ name: categoryName.toLowerCase() })
        ).unwrap();
        toast.success("Catégorie ajoutée avec succès");
      } else if (modalContent === "edit category") {
        if (!categoryName) {
          toast.error("Le nom de la catégorie est obligatoire");
          return;
        }
        await dispatch(
          updateCategory({
            id: editEntity.id,
            name: categoryName.toLowerCase(),
          })
        ).unwrap();
        toast.success("Catégorie mise à jour avec succès");
      } else if (modalContent === "Ajoute un nouvelle niveau") {
        if (!levelName) {
          toast.error("Le nom du niveau est requise");
          return;
        }
        if (!selectedCategory) {
          toast.error("La sélection de la catégorie est requise");
          return;
        }
        await dispatch(
          addLevel({
            name: levelName.toLowerCase(),
            categorie_id: selectedCategory,
          })
        ).unwrap();
        toast.success("Niveau ajouté avec succès");
      } else if (modalContent === "edit level") {
        if (!levelName) {
          toast.error("Le nom du niveau est obligatoire");
          return;
        }
        if (!selectedCategory) {
          toast.error("La sélection de la catégorie est obligatoire");
          return;
        }
        await dispatch(
          updateLevel({
            id: editEntity.id,
            name: levelName.toLowerCase(),
            category_id: selectedCategory,
          })
        ).unwrap();
        toast.success("Niveau mis à jour avec succès");
      }

      dispatch(fetchDashboardData(selectedYear));
      await dispatch(fetchLevels(selectcategorylevel));
      handleCloseModal();
    } catch (error) {
      if (error.response) {
        const { status } = error.response;
        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
      dispatch(clearStoreCategorie());
      dispatch(clearStoreRooms());
      dispatch(resetLevels());
    } finally {
      setIsLoadingRoom(false);
      setIsLoadingN(false);
      setIsLoadingC(false);
    }
  };

  const handleShowDeleteModal = (entityId, entityType) => {
    setDeleteEntity(entityId);
    setDeleteEntityType(entityType);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setDeleteEntity(null);
    setDeleteEntityType("");
  };

  const handleConfirmDelete = async () => {
    try {
      if (deleteEntityType === "room") {
        setIsLoadingRoom(true); // Show loading spinner for room table
        await dispatch(deleteRoom(deleteEntity)).unwrap();
        toast.success("Salle supprimée avec succès");
      } else if (deleteEntityType === "category") {
        setIsLoadingC(true); // Show loading spinner for category table
        await dispatch(deleteCategory(deleteEntity)).unwrap();
        toast.success("Catégorie supprimée avec succès");
      } else if (deleteEntityType === "level") {
        setIsLoadingN(true); // Show loading spinner for level table
        await dispatch(deleteLevel(deleteEntity)).unwrap();
        toast.success("Niveau supprimé avec succès");
      }

      // Fetch updated data after deletion
      dispatch(fetchDashboardData(selectedYear));
    } catch (error) {
      if (error.response) {
        switch (error.response.status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message);
            break;
        }
      } else {
        toast.error(error.message);
      }
      dispatch(clearStoreCategorie());
      dispatch(clearStoreRooms());
      dispatch(resetLevels());
    } finally {
      // Reset the loading states after the deletion is done
      setIsLoadingRoom(false);
      setIsLoadingC(false);
      setIsLoadingN(false);
      handleCloseDeleteModal();
    }
  };

  const handleDeleteRooms = (roomId) => {
    handleShowDeleteModal(roomId, "room");
  };

  const handleDeleteCategory = (categoryId) => {
    handleShowDeleteModal(categoryId, "category");
  };

  const handleDeleteLevel = (levelId) => {
    handleShowDeleteModal(levelId, "level");
  };

  const renderModalBody = () => {
    if (
      modalContent === "Ajoute une nouvelle salle" ||
      modalContent === "edit room"
    ) {
      return (
        <Form>
          <Form.Group controlId="formRoomName">
            <Form.Label>Nom Salle :</Form.Label>
            <Form.Control
              type="text"
              value={roomName}
              onChange={handleRoomNameChange}
              required
            />
          </Form.Group>
        </Form>
      );
    } else if (
      modalContent === "Ajoute une nouvelle Categorie" ||
      modalContent === "edit category"
    ) {
      return (
        <Form>
          <Form.Group controlId="formCategoryName">
            <Form.Label>Nom de la catégorie :</Form.Label>
            <Form.Control
              type="text"
              value={categoryName}
              onChange={handleCategoryNameChange}
              required
            />
          </Form.Group>
        </Form>
      );
    } else if (
      modalContent === "Ajoute un nouvelle niveau" ||
      modalContent === "edit level"
    ) {
      return (
        <Form>
          <Form.Group controlId="formLevelName">
            <Form.Label>Nom du niveau :</Form.Label>
            <Form.Control
              type="text"
              value={levelName}
              onChange={handleLevelNameChange}
              required
            />
          </Form.Group>
          <Form.Group controlId="formCategorySelect" className="mt-3">
            <Form.Control
              as="select"
              value={selectedCategory}
              onChange={handleCategoryChange}
              required
            >
              <option value="">Sélectionner une catégorie</option>
              {categories.map((category) => (
                <option key={category.id} value={category.id}>
                  {capitalizeFirstLetter(category.name)}
                </option>
              ))}
            </Form.Control>
          </Form.Group>
        </Form>
      );
    }
  };

  const cards = [
    { id: 1, text: "Étudiants", number: studentsCount, icon: <FaUser /> },
    {
      id: 2,
      text: "Profs",
      number: teachersCount,
      icon: <FaChalkboardTeacher />,
    },
    { id: 3, text: "Formations", number: formationsCount, icon: <FaList /> },
    { id: 4, text: "Salles ", number: rooms.length, icon: <FaDoorClosed /> },
  ];

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  console.log("auth", auth);
  return (
    <div className="container-fluied">
      <Row className="mb-5 mt-4">
        {cards.map((card) => (
          <Col key={card.id} md={3}>
            <Card className={`text-center mb-3  card-${card.id}`}>
              <Card.Body className="text-gradient">
                <Card.Title>
                  {card.text} : {card.number}
                </Card.Title>
                <div className="card-icon">
                  <div style={{ fontSize: "25px" }}>{card.icon}</div>
                </div>
              </Card.Body>
            </Card>
          </Col>
        ))}
      </Row>

      <div className="details-center">
        <div className="All-tables">
          {/* Salles Table */}
          <Col className="table-home">
            <div className="table-header d-flex justify-content-between align-items-center">
              <h5>Salles</h5>
              <FaPlus
                className="fa-plus"
                style={{ fontSize: "20px", color: "blue" }}
                onClick={() => handleShowModal("Ajoute une nouvelle salle")}
              />
            </div>
            <div className="tables-homes">
              {isLoadingRoom ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                  </Spinner>
                </div>
              ) : (
                <Table hover className="table-bordered">
                  <thead>
                    <tr>
                      <th>N</th>
                      <th>Salle</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {rooms.length > 0 ? (
                      rooms.map((room, index) => (
                        <tr key={room.id}>
                          <td>{index + 1}</td>
                          <td>{capitalizeFirstLetter(room.name)}</td>
                          <td className="text-center">
                            <FaEdit
                              className="action-icon me-3"
                              style={{ cursor: "pointer", color: "green" }}
                              onClick={() => handleShowModal("edit room", room)}
                            />
                            <FaTrash
                              className="action-icon"
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => handleDeleteRooms(room.id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Aucune Salles disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>

          {/* Categories Table */}
          <Col className="table-home">
            <div className="table-header d-flex justify-content-between align-items-center">
              <h5>Categories</h5>
              <FaPlus
                className="fa-plus"
                style={{ fontSize: "20px", color: "blue" }}
                onClick={() => handleShowModal("Ajoute une nouvelle Categorie")}
              />
            </div>
            <div className="tables-homes">
              {isLoadingC ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Chargement...</span>
                  </Spinner>
                </div>
              ) : (
                <Table hover className="table-bordered">
                  <thead>
                    <tr>
                      <th>N</th>
                      <th>Categorie</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {categories.length > 0 ? (
                      categories.map((category, index) => (
                        <tr key={category.id}>
                          <td>{index + 1}</td>
                          <td>{capitalizeFirstLetter(category.name)}</td>
                          <td className="text-center">
                            <FaEdit
                              className="action-icon me-3"
                              style={{ cursor: "pointer", color: "green" }}
                              onClick={() =>
                                handleShowModal("edit category", category)
                              }
                            />
                            <FaTrash
                              className="action-icon"
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => handleDeleteCategory(category.id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Aucune catégorie disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>

          <Col className="table-home">
            <div className="table-header d-flex justify-content-between align-items-center">
              <h5>Niveaux</h5>
              <Form.Group
                controlId="categorySelect"
                className="no-border-select"
              >
                <Form.Select
                  size="sm"
                  value={selectcategorylevel}
                  onChange={handleCategorylevelChange}
                >
                  <option value="">sélectionner categorie</option>
                  {categories.map((category) => (
                    <option key={category.id} value={category.id}>
                      {capitalizeFirstLetter(category.name)}
                    </option>
                  ))}
                </Form.Select>
              </Form.Group>
              <FaPlus
                className="fa-plus"
                style={{ fontSize: "20px", color: "blue" }}
                onClick={() => handleShowModal("Ajoute un nouvelle niveau")}
              />
            </div>
            <div className="tables-homes">
              {isLoadingN ? (
                <div className="text-center">
                  <Spinner animation="border" role="status">
                    <span className="visually-hidden">Loading...</span>
                  </Spinner>
                </div>
              ) : (
                <Table hover className="table-bordered">
                  <thead>
                    <tr>
                      <th>N</th>
                      <th>Niveau</th>
                      <th>Actions</th>
                    </tr>
                  </thead>
                  <tbody>
                    {levels.length > 0 && selectcategorylevel ? (
                      levels.map((level, index) => (
                        <tr key={level.id}>
                          <td>{index + 1}</td>
                          <td>{level?.name?.toUpperCase()}</td>
                          <td className="text-center">
                            <FaEdit
                              className="action-icon me-3"
                              style={{ cursor: "pointer", color: "green" }}
                              onClick={() =>
                                handleShowModal("edit level", level)
                              }
                            />
                            <FaTrash
                              className="action-icon"
                              style={{ cursor: "pointer", color: "red" }}
                              onClick={() => handleDeleteLevel(level.id)}
                            />
                          </td>
                        </tr>
                      ))
                    ) : (
                      <tr>
                        <td colSpan="3" className="text-center">
                          Aucune niveau disponible
                        </td>
                      </tr>
                    )}
                  </tbody>
                </Table>
              )}
            </div>
          </Col>
        </div>
        {auth.user?.role === "Director" && (
          <div className="mt-3">
            <h1>Statistiques des paiements</h1>
            <div
              className="mt-2"
              style={{
                height: "400px",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Line
                data={chartData}
                options={{
                  responsive: true,
                  scales: {
                    y: {
                      ticks: {
                        callback: function (value) {
                          return value + " DH";
                        },
                      },
                    },
                  },
                }}
                style={{ width: "85%" }}
              />
            </div>
          </div>
        )}
      </div>

      {/* Modal for Add/Edit */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>
            {modalContent === "Ajoute une nouvelle salle" &&
              "Ajoute une nouvelle salle"}
            {modalContent === "edit room" && "Modifie Salle"}
            {modalContent === "Ajoute une nouvelle Categorie" &&
              "Ajoute une nouvelle Categorie"}
            {modalContent === "edit category" && "Modifie Categorie"}
            {modalContent === "Ajoute un nouvelle niveau" &&
              "Ajoute un nouvelle niveau"}
            {modalContent === "edit level" && "Modifie Niveau"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderModalBody()}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Fermer
          </Button>
          <Button
            variant="primary"
            onClick={handleSubmit}
            disabled={isLoadingRoom || isLoadingC || isLoadingN}
          >
            {(modalContent === "Ajoute une nouvelle salle" ||
              modalContent === "edit room") &&
            isLoadingRoom ? (
              <Spinner animation="border" size="sm" />
            ) : null}
            {(modalContent === "Ajoute une nouvelle Categorie" ||
              modalContent === "edit category") &&
            isLoadingC ? (
              <Spinner animation="border" size="sm" />
            ) : null}
            {(modalContent === "Ajoute un nouvelle niveau" ||
              modalContent === "edit level") &&
            isLoadingN ? (
              <Spinner animation="border" size="sm" />
            ) : null}
            Enregistrer
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmer la suppression</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {deleteEntityType === "room" &&
            "Êtes-vous sûr de vouloir supprimer cette salle"}
          {deleteEntityType === "category" &&
            "Êtes-vous sûr de vouloir supprimer cette catégorie ?"}
          {deleteEntityType === "level" &&
            "Êtes-vous sûr de vouloir supprimer ce niveau ?"}
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Fermer
          </Button>
          <Button variant="danger" onClick={handleConfirmDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default Home;
