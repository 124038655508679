import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axiosPrivate from "../../api/axios";

export const fetchStudents = createAsyncThunk(
  "groupStudents/fetchStudents",
  async (groupId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/group-students/${groupId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const addStudent = createAsyncThunk(
  "groupStudents/addStudent",
  async (studentData, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.post("/group-students", studentData);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.response?.data?.message || error.message,
        status: error.response?.status,
        data: error.response?.data,
      });
    }
  }
);

export const updateStudent = createAsyncThunk(
  "groupStudents/updateStudent",
  async ({ id, updatedData }, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.put(
        `/group-students/${id}`,
        updatedData
      );
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const deleteStudent = createAsyncThunk(
  "groupStudents/deleteStudent",
  async (id, { rejectWithValue }) => {
    try {
      await axiosPrivate.delete(`/group-students/${id}`);
      return id;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

export const leavingGroup = createAsyncThunk(
  "groupStudents/leavingGroup",
  async (studentId, { rejectWithValue }) => {
    try {
      const response = await axiosPrivate.get(`/leave-group/${studentId}`);
      return response.data;
    } catch (error) {
      return rejectWithValue({
        message: error.message,
        status: error.response?.status,
      });
    }
  }
);

// Create the slice
const groupStudentsSlice = createSlice({
  name: "groupStudents",
  initialState: {
    activeStudents: [],
    finishedStudents: [],
    student: {},
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStudents.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchStudents.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.activeStudents = action.payload.activeStudents;
        state.finishedStudents = action.payload.finishedStudents;
        state.student = action.payload;
      })
      .addCase(fetchStudents.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(addStudent.fulfilled, (state, action) => {
        const newStudent = action.payload;
        state.activeStudents.push(newStudent);
      })
      .addCase(addStudent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload.message;
      })
      .addCase(updateStudent.fulfilled, (state, action) => {
        const updatedStudent = action.payload;
        const index = state.activeStudents.findIndex(
          (student) => student.id === updatedStudent.id
        );
        if (index !== -1) {
          state.activeStudents[index] = updatedStudent;
        }
      })
      .addCase(updateStudent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(deleteStudent.fulfilled, (state, action) => {
        const id = action.payload;
        state.activeStudents = state.activeStudents.filter(
          (student) => student.id !== id
        );
      })
      .addCase(deleteStudent.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(leavingGroup.pending, (state) => {
        state.status = "loading";
      })
      .addCase(leavingGroup.fulfilled, (state, action) => {
        state.status = "succeeded";
        const studentId = action.payload.id;
        state.activeStudents = state.activeStudents.filter(
          (student) => student.id !== studentId
        );
      })
      .addCase(leavingGroup.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      });
  },
});

export default groupStudentsSlice.reducer;
