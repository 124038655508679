import React, { useEffect } from "react";
import { Modal, Button, Row, Col } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { fetchGroupById } from "../../Redux/groupe/Group";

const GroupDetailsModal = ({
  showGroupDetailsModal,
  handleCloseGroupDetails,
  groupDetails,
}) => {
  const dispatch = useDispatch();

  useEffect(() => {
    const fetchUpdatedGroupData = async () => {
      if (groupDetails?.id) {
        await dispatch(fetchGroupById(groupDetails.id));
      }
    };

    fetchUpdatedGroupData();
  }, [dispatch, groupDetails?.id]);

  return (
    <Modal
      show={showGroupDetailsModal}
      onHide={handleCloseGroupDetails}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Détails du Groupe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {groupDetails && (
          <div>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Nom :</span>
                  <strong style={{ fontSize: "15px" }}>
                    {" "}
                    {groupDetails.name}
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span> Prof : </span>
                  <strong style={{ fontSize: "15px" }}>
                    {groupDetails.teacher?.nom}&nbsp;
                    {groupDetails.teacher?.prenom}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Niveau :</span>
                  <strong style={{ fontSize: "15px" }}>
                    {groupDetails.level?.name?.toUpperCase() || "non existant"}
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Durée Formation :</span>
                  <strong style={{ fontSize: "15px" }}>
                    {groupDetails.choice?.formation_duration === 0
                      ? "Par mois"
                      : groupDetails.choice?.formation_duration + " Mois"}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Durée Séance:</span>
                  <strong style={{ fontSize: "15px" }}>
                    {groupDetails.choice?.session_duration} H
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Nbr Séance / Semaine : </span>
                  <strong style={{ fontSize: "15px" }}>
                    {groupDetails.choice?.session_nb}
                  </strong>
                </p>
              </Col>
            </Row>
            <Row className="mb-3">
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Méthode :</span>
                  <strong style={{ fontSize: "15px" }}>
                    {" "}
                    {groupDetails.choice?.teaching_method}
                  </strong>
                </p>
              </Col>
              <Col>
                <p style={{ fontSize: "17px" }}>
                  <span>Prix :</span>
                  <strong style={{ fontSize: "15px" }}>
                    {" "}
                    {groupDetails.choice?.price} Dh
                  </strong>
                </p>
              </Col>
            </Row>
            <Col>
              <p style={{ fontSize: "17px" }}>
                <span>Etat : </span>
                <span
                  className={`badge ${
                    groupDetails.status === "in progress" ? "bg-success" : "bg-danger"
                  }`}
                >
                  {groupDetails.status === "in progress" ? "Active" : "Inactive"}
                </span>
              </p>
            </Col>
          </div>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleCloseGroupDetails}>
          Fermer
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default GroupDetailsModal;
