import { Modal, Button, Table } from "react-bootstrap";
import moment from "moment";
import { FaPlus, FaUserCheck, FaUserTimes } from "react-icons/fa";
import { useDispatch } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { updateSession } from "../../Redux/groupSessions/groupSessions";
import {
  addAbsence,
} from "../../Redux/groupeSessionAbsentStudent/sessionAbsentSlice";
import { toast } from "react-toastify";

const EventDetailsModal = ({
  show,
  handleClose,
  setDetailsSession,
  DetailsSession,
  handleDeleteRepeating,
  handleDeleteSession,
  handleCheckboxChangeProf,
  teacherAbsent,
}) => {
  const dispatch = useDispatch();

  const [showTextarea, setShowTextarea] = useState(false);

  const [description, setDescription] = useState(
    DetailsSession.description || ""
  );
  const [actuellAbsences, setActuellAbsences] = useState([]);


  const res = useMemo(() => {
    return DetailsSession?.studentsOfSession?.filter(
      (row1) =>
        !DetailsSession?.absencesOfSession?.some(
          (row2) => row2.student.id === row1.student.id
        )
    );
  }, [DetailsSession]);
  useEffect(() => {
    if (DetailsSession?.studentsOfSession?.length && !DetailsSession?.absencesOfSession.length) {
      setActuellAbsences(
        DetailsSession?.studentsOfSession.map((item) => ({
          id: item.id,
          student: {
            id: item.student.id,
            nom: item.student.nom,
            prenom: item.student.prenom,
          },
          isAbsent: false,
        }))
      );
    } else if (DetailsSession?.studentsOfSession?.length || DetailsSession?.absencesOfSession?.length) {
      setActuellAbsences([
        ...DetailsSession?.absencesOfSession,
        ...res?.map((item) => ({
          id: item.id,
          student: {
            id: item.student.id,
            nom: item.student.nom,
            prenom: item.student.prenom,
          },
          isAbsent: false,
        })),
      ]);
    } else {
      setActuellAbsences([]);
    }
  }, [DetailsSession]);
  // function de fetch description
  useEffect(() => {
    if (DetailsSession) {
      setDescription(DetailsSession.session?.description);
    }
  }, [DetailsSession]);

  // add description
  const toggleTextarea = () => {
    setShowTextarea((prev) => !prev);
    if (showTextarea) {
      dispatch(
        updateSession({
          id: DetailsSession.session?.id,
          sessionData: { description: description?.toLowerCase() },
        })
      );
    }
  };
  console.log("DetailsSession", DetailsSession);
  const iconeAbsenceState = () => {
    return res.length || !DetailsSession?.absencesOfSession.length ? (
      <FaUserTimes
        style={{
          color: "red",
          fontSize: "30px",
        }}
      />
    ) : (
      <FaUserCheck style={{ color: "green", fontSize: "30px" }} />
    );
  };
  const handleSaveAbsences = async () => {
    try {
      const absenceData = {
        session_id: DetailsSession.session?.id,
        absences: actuellAbsences.map((item) => ({
          student_id: item.student.id,
          isAbsent: item.isAbsent,
        })),
      };
      dispatch(addAbsence(absenceData));
      setDetailsSession({...DetailsSession,absencesOfSession:actuellAbsences})
      toast.success("Les absences ont été enregistrées avec succès!");
    } catch (error) {
      toast.error(
        "Une erreur s'est produite lors de l'enregistrement des absences."
      );
    }
  };

  const handleCheckboxChange = (student_id) => {
    const newTable = actuellAbsences.map((item) =>
      item.student.id === student_id
        ? { ...item, isAbsent: !item.isAbsent }
        : item
    );
    setActuellAbsences(newTable);
  };
  // function de capital first letter
  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <Modal
      show={show}
      onHide={() => {
        handleClose();
        setActuellAbsences([]);
      }}
      size="lg"
    >
      <Modal.Header closeButton>
        <Modal.Title>Détails de la Séance Groupe</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <h5
          className="text-center mb-1"
          style={{
            background: "#0d6efd",
            color: "white",
            borderRadius: "5px",
            padding: "2px",
          }}
        >
          Details Séance
        </h5>
        <div style={{ height: "25vh", overflowY: "hidden" }}>
          <Table striped bordered responsive>
            <thead>
              <tr className="text-center align-middle">
                <th>Jour</th>
                <th>Heure de début</th>
                <th>Heure de fin</th>
                <th>Prof</th>
                <th>{DetailsSession.session?.room?.name ? "Salle" : "type"} </th>
                <th>{teacherAbsent ? "Absente" : "Présent"} Prof</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center align-middle">
                <td>{moment(DetailsSession.session?.start).format("YYYY-MM-DD")}</td>
                <td>{moment(DetailsSession.session?.start).format("HH:mm")}</td>
                <td>{moment(DetailsSession.session?.end).format("HH:mm")}</td>
                <td>
                  {capitalizeFirstLetter(DetailsSession.session?.teacher?.nom)}{" "}
                  {capitalizeFirstLetter(DetailsSession.session?.teacher?.prenom)}
                </td>
                <td>
                  {DetailsSession.session?.room?.name?.toUpperCase() || "à distance"}
                </td>
                <td className="text-center">
                  <input
                    type="checkbox"
                    checked={teacherAbsent}
                    onChange={handleCheckboxChangeProf}
                  />
                </td>
              </tr>
            </tbody>
          </Table>
        </div>

        <div>
          <div className="d-flex justify-content-center align-items-center">
            <h5>Description</h5>
            <FaPlus
              className="fa-plus"
              style={{ marginBottom: "5px", color: "blue", size: "20px" }}
              onClick={toggleTextarea}
            />
          </div>
          {showTextarea && (
            <textarea
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              className="form-control"
            />
          )}
          {!showTextarea && description && (
            <p className="text-muted mt-2">{description}</p>
          )}
          {showTextarea && !description && (
            <p className="text-muted">Aucune description fournie.</p>
          )}
        </div>

        <h6
          className="text-center "
          style={{
            background: "#0d6efd",
            color: "white",
            borderRadius: "5px",
            padding: "2px",
          }}
        >
          Étudiants inscrits
        </h6>
        {teacherAbsent ? (
          <p className="text-center">
            Le professeur est absent, donc la liste des étudiants ne peut pas
            être affichée.
          </p>
        ) : (
          <>
            <div style={{ width: "98%" }}>
              <p className="text-end">
                {actuellAbsences.length ? iconeAbsenceState() : ""}
              </p>
            </div>

            <div style={{ overflowX: "auto", maxHeight: "180px" }}>
              <Table striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th>Étudiant</th>
                    <th>Absent</th>
                  </tr>
                </thead>
                <tbody>
                  {actuellAbsences.length ? (
                    actuellAbsences?.map((student) => (
                      <tr key={student.student?.id} className="text-center">
                        <td>
                          {capitalizeFirstLetter(student.student?.nom) +
                            " " +
                            capitalizeFirstLetter(student.student?.prenom)}
                        </td>
                        <td>
                          <input
                            type="checkbox"
                            checked={student.isAbsent}
                            onChange={() =>
                              handleCheckboxChange(student.student.id)
                            }
                          />
                        </td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="2" className="text-center">
                        Aucun étudiant inscrit
                      </td>
                    </tr>
                  )}
                </tbody>
              </Table>
            </div>
          </>
        )}
      </Modal.Body>
      <Modal.Footer>
        <div className="d-flex justify-content-between w-100">
          <div>
            {DetailsSession.session?.repeating_id !== null && (
              <Button
                variant="danger"
                className="m-2"
                size="sm"
                onClick={handleDeleteRepeating}
              >
                Supprimer séries
              </Button>
            )}
            <Button
              variant="danger"
              size="sm"
              onClick={handleDeleteSession}
              className="m-2"
            >
              Supprimer ceci
            </Button>
          </div>
          {actuellAbsences.length > 0 && (
            <div className="text-center">
              <Button
                variant="primary"
                size="sm"
                onClick={handleSaveAbsences}
                className="m-2"
              >
                Enregistrer des présents
              </Button>
            </div>
          )}
        </div>
      </Modal.Footer>
    </Modal>
  );
};

export default EventDetailsModal;
