import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  fetchStudents,
  deleteStudent,
  updateStudent,
  leavingGroup,
} from "../../Redux/groupStudents/GroupStudentsSlice";
import { fetchNextThreeSessions } from "../../Redux/groupSessions/groupSessions";
import {
  fetchStudentPayments,
  nextPayment,
} from "../../Redux/payment/paymentSlice";
import { Button, Modal, Table, Form, Nav } from "react-bootstrap";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  FaCreditCard,
  FaEdit,
  FaEye,
  FaMoneyBill,
  FaSignOutAlt,
  FaTrash,
} from "react-icons/fa";
import moment from "moment";

export const GroupeEtudent = () => {
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, setActiveTab] = useState("active");

  const students = useSelector((state) => state.groupStudents.activeStudents);
  const studentsFinish = useSelector(
    (state) => state.groupStudents.finishedStudents
  );

  const isActive = useSelector((state) => state.groupStudents.student);
  const status = useSelector((state) => state.groupStudents.status);

  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [studentToDelete, setStudentToDelete] = useState(null);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [studentToUpdate, setStudentToUpdate] = useState(null);
  const [selectedSession, setSelectedSession] = useState(null);

  const paymentDetails = useSelector((state) => state.payments.paymentDetails);

  const [idStudent, SetIdstudent] = useState([]);

  const [showPaymentModal, setShowPaymentModal] = useState(false);

  const [showLeaveConfirmationModal, setShowLeaveConfirmationModal] = useState(false);
  const [studentToLeave, setStudentToLeave] = useState(null);


  useEffect(() => {

    if (showPaymentModal && idStudent?.student?.id) {
      dispatch(fetchStudentPayments({
        student_id: idStudent.student.id,
        ticket_id: idStudent.id,
      }));
    }
  }, [dispatch, showPaymentModal, idStudent]); 
  

  const [showDetailModal, setShowDetailModal] = useState(false);
  const [selectedPaymentDetails, setSelectedPaymentDetails] = useState(null);

  const handleViewDetails = (payment) => {
    setShowDetailModal(true);
    setSelectedPaymentDetails(payment);
  };

  // const [sessions, setSessions] = useState([]);

  const session = useSelector((s) => s.groupSessions.sessions);

  // Function to generate next payment
  const handleGenerateNextPayment = async (student) => {
    if (!student) {
      toast.error("Données de l'étudiant invalides.");
      return;
    }
    if (!student.firstSession?.first_session) {
      toast.error("Cet élève n'a pas encore commencé ses études.");
      return;
    }
    try {
      await dispatch(nextPayment({ studentId: student.id })).unwrap();
      toast.success("Le prochain paiement a été généré avec succès !");
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
  };

  useEffect(() => {
    const loadStudents = async () => {
      try {
        await dispatch(fetchStudents(groupId)).unwrap();
      } catch (err) {
        toast.error("Error fetching students.");
      }
    };
    loadStudents();

    const loadSessions = async () => {
      try {
        await dispatch(fetchNextThreeSessions(groupId)).unwrap();
      } catch (err) {
        toast.error("Error fetching sessions.");
      }
    };
    loadSessions();
  }, [dispatch, groupId]);

  const handleShowDeleteModal = (id) => {
    setStudentToDelete(id);
    setShowDeleteModal(true);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
    setStudentToDelete(null);
  };

  const handleDeleteStudent = async () => {
    if (studentToDelete) {
      try {
        await dispatch(deleteStudent(studentToDelete)).unwrap();
        dispatch(fetchStudents(groupId));

        toast.success("Student deleted successfully!");
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        handleCloseDeleteModal();
      }
    }
  };

  const handleShowUpdateModal = (student) => {
    setStudentToUpdate(student);
    setShowUpdateModal(true);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
    setStudentToUpdate(null);
    setSelectedSession(null);
  };

  const handleUpdateStudent = async () => {
    if (studentToUpdate && selectedSession) {
      try {
        await dispatch(
          updateStudent({
            id: studentToUpdate.id,
            updatedData: {
              group_id: groupId,
              student_id: studentToUpdate.id,
              first_session: selectedSession,
            },
          })
        ).unwrap();
        dispatch(fetchStudents(groupId));
        toast.success("Student updated successfully!");
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      } finally {
        handleCloseUpdateModal();
      }
    } else {
      toast.error("Please select a session.");
    }
  };

  if (status === "loading") {
    return <div>Loading...</div>;
  }

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  const handleTabSelect = (tab) => {
    setActiveTab(tab);
  };

  const handleLeaveGroup = async (studentId) => {
    const student = students.find((s) => s.student.id === studentId.student.id);

    if (!student?.firstSession?.first_session) {
      toast.error(
        "Cet étudiant n'a pas encore assisté à la première séance et ne peut pas quitter le groupe."
      );
      return;
    }
    try {
      await dispatch(leavingGroup(studentId.id)).unwrap();
      dispatch(fetchStudents(groupId));
      toast.success("Étudiant a quitté le groupe avec succès.");
    } catch (error) {
      if (error.status === 400) {
        toast.error("il n'a étduié aucun cours.");
      } else {
        toast.error("Erreur lors de la sortie du groupe.");
      }
    }
  };

  const handleShowLeaveConfirmationModal = (student) => {
    setStudentToLeave(student);
    setShowLeaveConfirmationModal(true);
  };

  const handleCloseLeaveConfirmationModal = () => {
    setShowLeaveConfirmationModal(false);
    setStudentToLeave(null);
  };



  return (
    <div>
      <h2>Étudiant dans le groupe</h2>
      <Nav variant="tabs" activeKey={activeTab} onSelect={handleTabSelect}>
        <Nav.Item>
          <Nav.Link eventKey="active">Les étudiants actuels : {students.length} </Nav.Link>
        </Nav.Item>
        <Nav.Item>
          <Nav.Link eventKey="inactive">Les étudiants quittes</Nav.Link>
        </Nav.Item>
      </Nav>

      {activeTab === "active" && (
        <Table bordered hover size="sm" className="mt-1">
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Nom</th>
              <th>Premier Séance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {students && students.length > 0 ? (
              students.map((student, index) => (
                <tr key={student.student?.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(student.student?.name)}</td>
                  <td>
                    {student.firstSession?.first_session
                      ? new Date(student.firstSession.first_session)
                          .toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                          .replace(/^([a-z])/, (match) => match?.toUpperCase())
                      : "Non défini pour le moment"}
                  </td>
                  <td>
                    <FaTrash
                      className="m-2 "
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleShowDeleteModal(student.id)}
                    />
                    <FaEdit
                      className="m-2 "
                      style={{ color: "green", cursor: "pointer" }}
                      onClick={() => handleShowUpdateModal(student)}
                    />
                    {isActive?.groupActive && isActive?.nextPayment && (
                      <FaCreditCard
                        className="m-2"
                        style={{
                          color: student.firstSession?.first_session
                            ? "#28a745"
                            : "gray",
                          cursor: student.firstSession?.first_session
                            ? "pointer"
                            : "not-allowed",
                        }}
                        onClick={() => handleGenerateNextPayment(student)}
                        disabled={!student.firstSession?.first_session}
                      />
                    )}

                    <FaMoneyBill
                      className="m-2"
                      size={21}
                      style={{ color: "#4caf50", cursor: "pointer" }}
                      onClick={() => {
                        SetIdstudent(student);
                        setShowPaymentModal(true);
                      }}
                    />
                    <FaSignOutAlt
                      className="m-2"
                      style={{ color: "orange", cursor: "pointer" }}
                      onClick={() => handleShowLeaveConfirmationModal(student)}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="4"
                  style={{ textAlign: "center", backgroundColor: "red" }}
                >
                  Non trouvé
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      {activeTab === "inactive" && (
        <Table bordered hover size="sm" className="mt-1">
          <thead>
            <tr className="text-center">
              <th>N</th>
              <th>Nom</th>
              <th>Premier Séance</th>
              <th>Derniere Séance</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {studentsFinish && studentsFinish.length > 0 ? (
              studentsFinish.map((student, index) => (
                <tr key={student.student.id} className="text-center">
                  <td>{index + 1}</td>
                  <td>{capitalizeFirstLetter(student.student?.name)}</td>
                  <td>
                    {student.firstSession?.first_session
                      ? new Date(student.firstSession.first_session)
                          .toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                          .replace(/^([a-z])/, (match) => match?.toUpperCase())
                      : "Non défini pour le moment"}
                  </td>
                  <td>
                    {student.endSession?.end_session
                      ? new Date(student.endSession?.end_session)
                          .toLocaleDateString("fr-FR", {
                            weekday: "long",
                            year: "numeric",
                            month: "long",
                            day: "numeric",
                          })
                          .replace(/^([a-z])/, (match) => match?.toUpperCase())
                      : "Non défini pour le moment"}
                  </td>
                  <td>
                    <FaTrash
                      className="m-2 "
                      style={{ color: "red", cursor: "pointer" }}
                      onClick={() => handleShowDeleteModal(student.id)}
                    />
                    <FaMoneyBill
                      className="m-2"
                      size={21}
                      style={{ color: "#4caf50", cursor: "pointer" }}
                      onClick={() => {
                        SetIdstudent(student);
                        setShowPaymentModal(true);
                      }}
                    />
                  </td>
                </tr>
              ))
            ) : (
              <tr>
                <td
                  colSpan="5"
                  style={{ textAlign: "center", backgroundColor: "red" }}
                >
                  Non trouvé
                </td>
              </tr>
            )}
          </tbody>
        </Table>
      )}

      {/* Delete Confirmation Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation suppresion </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer cet étudiant ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={handleDeleteStudent}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Update Student Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Modifier Étudiant</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form>
            <Form.Group>
              <Form.Label>Nom Étudiant </Form.Label>
              <Form.Control
                type="text"
                value={studentToUpdate?.student?.name || ""}
                readOnly
              />
            </Form.Group>
            <Form.Group className="mt-3">
              <Form.Label>Sélectionner Premier Seance:</Form.Label>
              <div className="groupSeance mt-4">
                {session.map((session) => (
                  <div
                    key={session.id}
                    className={`seance ${
                      selectedSession === session.id ? "active" : ""
                    }`}
                    onClick={() => {
                      setSelectedSession(session.id);
                    }}
                  >
                    <div className="date">
                      {new Date(session.start).toLocaleDateString()}
                    </div>
                    <div className="day">
                      {new Date(session.start).toLocaleString("fr-FR", {
                        weekday: "long",
                      })}
                    </div>
                    <div className="time">
                      {new Date(session.start).toLocaleTimeString([], {
                        hour: "2-digit",
                        minute: "2-digit",
                        hour12: false,
                      })}
                    </div>
                  </div>
                ))}
              </div>
            </Form.Group>
          </Form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Annuler
          </Button>
          <Button variant="primary" onClick={handleUpdateStudent}>
            Modifier
          </Button>
        </Modal.Footer>
      </Modal>

      {/* affiche paiment */}
      <Modal
        show={showPaymentModal}
        onHide={() => setShowPaymentModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Paiement Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Table bordered responsive >
            <thead>
              <tr className="text-center">
                <th>N</th>
                <th>Montant</th>
                <th>Reste</th>
                <th>Paye</th>
                <th>Etat</th>
                <th>Date</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {paymentDetails.map((payment, index) => {
                const totalPartials =
                  payment.partials?.reduce(
                    (sum, partial) => sum + partial.amount,
                    0
                  ) || 0;
                const remainingAmount = payment.amount - totalPartials;

                return (
                  <tr key={payment.id} className="text-center">
                    <td>{index + 1}</td>
                    <td>{payment.amount} Dh</td>
                    <td>{remainingAmount} Dh</td>
                    <td>{totalPartials} Dh</td>
                    <td>
                      {payment.status === 0 ? (
                        <span
                          className="badge bg-danger"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Impayé
                        </span>
                      ) : payment.status === 1 ? (
                        <span
                          className="badge bg-success"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Complété
                        </span>
                      ) : payment.status === 2 ? (
                        <span
                          className="badge bg-warning"
                          style={{ width: "70px", textAlign: "center" }}
                        >
                          Restant
                        </span>
                      ) : null}
                    </td>
                    <td>{moment(payment.invoice_date).format("YYYY/MM/DD")}</td>
                    <td>
                      <div className="text-center d-flex justify-content-center">
                        <FaEye
                          className="m-2"
                          style={{ color: "green", cursor: "pointer" }}
                          onClick={() => handleViewDetails(payment)}
                        />
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </Modal.Body>
      </Modal>

      {/* modal for details partails  */}
      <Modal
        show={showDetailModal}
        onHide={() => setShowDetailModal(false)}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Détails des Paiements Partiels</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPaymentDetails && (
            <table className="table">
              <thead>
                <tr className="text-center">
                  <th>N</th>
                  <th>Date </th>
                  <th>Méthode </th>
                  <th>Montant</th>
                </tr>
              </thead>
              <tbody>
                {selectedPaymentDetails.partials?.map((partial, index) => (
                  <tr key={index} className="text-center">
                    <td>{index + 1}</td>
                    <td>
                      {moment(partial.paid_on).format("YYYY/MM/DD HH:mm:ss")}
                    </td>

                    <td>
                      {" "}
                      {partial.payment_method === "Cash"
                        ? "Espèce"
                        : partial.payment_method === "Western union/CashPlus"
                        ? "CashPlus"
                        : partial.payment_method === "Bank transfer"
                        ? "Virement Bancaire"
                        : partial.payment_method}
                    </td>
                    <td>{partial.amount} Dh</td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}

          <Button variant="secondary" onClick={() => setShowDetailModal(false)}>
            Fermer
          </Button>
        </Modal.Body>
      </Modal>


            {/* Confirmation Modal for Leaving Group */}
            <Modal show={showLeaveConfirmationModal} onHide={handleCloseLeaveConfirmationModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation de sortie du groupe</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir que cet étudiant quitte le groupe ?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseLeaveConfirmationModal}>
            Annuler
          </Button>
          <Button
            variant="danger"
            onClick={() => {
              handleLeaveGroup(studentToLeave);
              handleCloseLeaveConfirmationModal();
            }}
          >
            Confirmer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
