import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteArchivedStudent,
  fetchArchivedStudents,
  setCurrentPage as setStudentCurrentPage,
} from "../../Redux/Etudent/archivedStudentsSlice";
import { FaTrash } from "react-icons/fa";
import { Modal, Button, Pagination, Row, Col } from "react-bootstrap";
import {
  deleteArchivedTeacher,
  fetchArchivedTeachers,
  setCurrentPage as setTeacherCurrentPage,
} from "../../Redux/Profs/archivedTeachersSlice";
import { toast } from "react-toastify";
import moment from "moment";

export const Archive = () => {
  const [activeTab, setActiveTab] = useState("table1");
  const [showModal, setShowModal] = useState(false);
  const [idToDelete, setIdToDelete] = useState(null);
  const [isStudent, setIsStudent] = useState(false);
  const [searchTerm] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { selectedYear } = useSelector((state) => state.schoolYears);
  // Student state
  const {
    loading,
    totalStudents,
    currentPage: studentCurrentPage,
    pageSize,
  } = useSelector((state) => state.archivedStudents);
  const archivedStudents = useSelector(
    (state) => state.archivedStudents.archivedStudents
  );
  // Teacher state
  const {
    archivedTeachers,
    loading: teacherLoading,
    currentPage: teacherCurrentPage,
    pageSize: teacherPageSize,
    totalTeachers,
  } = useSelector((state) => state.archivedTeachers);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeTab === "table1") {
          await dispatch(
            fetchArchivedTeachers({
              page: teacherCurrentPage,
              pageSize,
              searchTerm,
            })
          );
        }
      } catch (error) {
        const status = error?.status || error?.response?.status;

        switch (status) {
          case 403:
            navigate("/Forbidden");
            break;
          case 404:
            navigate("/not-found");
            break;
          case 401:
            navigate("/Unauthorized");
            break;
          case 500:
            navigate("/internal-error");
            break;
          case 402:
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
            break;
          default:
            toast.error(error.message || "An unexpected error occurred.");
            break;
        }
      }
    };
    fetchData();
  }, [activeTab, dispatch, teacherCurrentPage, pageSize, searchTerm, navigate]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (activeTab === "table2" && selectedYear) {
          await dispatch(
            fetchArchivedStudents({
              yearId: selectedYear,
              page: studentCurrentPage,
              pageSize,
              searchTerm,
            })
          ).unwrap();
        }
      } catch (error) {
        if (error.response) {
          if (error.response.status === 403) {
            navigate("/Forbidden");
          } else if (error.response.status === 404) {
            navigate("/not-found");
          } else if (error.response.status === 401) {
            navigate("/Unauthorized");
          } else if (error.response.status === 500) {
            navigate("/internal-error");
          } else if (error.response.status === 402) {
            toast.error(
              "Abonnement terminé. Veuillez renouveler votre abonnement."
            );
          }
        }
      }
    };
    fetchData();
  }, [
    dispatch,
    selectedYear,
    studentCurrentPage,
    pageSize,
    searchTerm,
    activeTab,
    navigate,
  ]);

  const handleDeleteClick = (id, isStudentFlag) => {
    setIdToDelete(id);
    setIsStudent(isStudentFlag);
    setShowModal(true);
  };

  const confirmDelete = async () => {
    try {
      if (isStudent) {
        await dispatch(deleteArchivedStudent(idToDelete)).unwrap();

        await dispatch(
          fetchArchivedStudents({
            yearId: selectedYear,
            page: studentCurrentPage,
            pageSize,
            searchTerm,
          })
        ).unwrap();

        if (archivedStudents.length === 0 && studentCurrentPage > 1) {
          dispatch(setStudentCurrentPage(studentCurrentPage - 1));
        }
      } else {
        await dispatch(deleteArchivedTeacher(idToDelete)).unwrap();

        await dispatch(
          fetchArchivedTeachers({
            page: teacherCurrentPage,
            pageSize,
            searchTerm,
          })
        ).unwrap();

        if (archivedTeachers.length === 0 && teacherCurrentPage > 1) {
          dispatch(setTeacherCurrentPage(teacherCurrentPage - 1));
        }
      }
      setShowModal(false);
      setIdToDelete(null);
    } catch (error) {
      const status = error?.status || error?.response?.status;

      switch (status) {
        case 403:
          navigate("/Forbidden");
          break;
        case 404:
          navigate("/not-found");
          break;
        case 401:
          navigate("/Unauthorized");
          break;
        case 500:
          navigate("/internal-error");
          break;
        case 402:
          toast.error(
            "Abonnement terminé. Veuillez renouveler votre abonnement."
          );
          break;
        default:
          toast.error(error.message || "An unexpected error occurred.");
          break;
      }
    }
  };

  const handleCloseModal = () => {
    setShowModal(false);
    setIdToDelete(null);
  };

  // const handleSearchChange = (event) => {
  //   const { value } = event.target;
  //   setSearchTerm(value);
  //   if (activeTab === "table1") {
  //     dispatch(setTeacherCurrentPage(1));
  //     dispatch(fetchArchivedTeachers({ page: 1, pageSize, searchTerm: value }));
  //   } else {
  //     dispatch(setStudentCurrentPage(1));
  //     dispatch(
  //       fetchArchivedStudents({
  //         yearId: selectedYear,
  //         page: 1,
  //         pageSize,
  //         searchTerm: value,
  //       })
  //     );
  //   }
  // };

  const handlePageChange = (page) => {
    if (activeTab === "table1") {
      dispatch(setTeacherCurrentPage(page));
    } else {
      dispatch(setStudentCurrentPage(page));
    }
  };

  const totalTeacherPages = Math.ceil(totalTeachers / teacherPageSize);

  const capitalizeFirstLetter = (str) => {
    if (!str) return str;
    return str.charAt(0)?.toUpperCase() + str.slice(1)?.toLowerCase();
  };

  return (
    <div className="container ">
      <Row className="align-items-center mb-3">
        <Col xs={12} md={6} className="text-md-start text-center">
          <h3>Tableau des archives</h3>
        </Col>
      </Row>
      <ul className="nav nav-tabs">
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === "table1" ? "active" : ""}`}
            onClick={() => setActiveTab("table1")}
          >
            Prof
          </Link>
        </li>
        <li className="nav-item">
          <Link
            className={`nav-link ${activeTab === "table2" ? "active" : ""}`}
            onClick={() => setActiveTab("table2")}
          >
            Étudiant
          </Link>
        </li>
      </ul>

      {activeTab === "table1" && (
        <>
          {teacherLoading && <p>Loading prof...</p>}
          <div className="table-responsive">
            <table className="table mt-3 ">
              <thead>
                <tr>
                  <th>N</th>
                  <th>Nom</th>
                  <th>Email</th>
                  <th>Téléphone</th>
                  <th>CIN</th>
                  <th>RIB</th>
                  <th>Pays</th>
                  <th>Ville</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {archivedTeachers.map((teacher, index) => (
                  <tr key={teacher.id}>
                    <td>{index + 1}</td>
                    <td>
                      {capitalizeFirstLetter(teacher.nom)}{" "}
                      {capitalizeFirstLetter(teacher.prenom)}
                    </td>
                    <td>{teacher.email}</td>
                    <td>{teacher.phone}</td>
                    <td>{teacher.cin?.toUpperCase()}</td>
                    <td>{teacher.rib}</td>
                    <td>{capitalizeFirstLetter(teacher.country)}</td>
                    <td>{capitalizeFirstLetter(teacher.city)}</td>
                    <td className="d-flex col-12 justify-content-center">
                      <FaTrash
                        className="m-2"
                        style={{ color: "red" }}
                        onClick={() => handleDeleteClick(teacher.id, false)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>

          {/* Pagination Controls for Teachers */}
          <Row className="justify-content-center">
            <Col xs="auto">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={teacherCurrentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(teacherCurrentPage - 1)}
                  disabled={teacherCurrentPage === 1}
                />
                {Array.from({ length: totalTeacherPages }, (_, index) => (
                  <Pagination.Item
                    key={index + 1}
                    active={index + 1 === teacherCurrentPage}
                    onClick={() => handlePageChange(index + 1)}
                  >
                    {index + 1}
                  </Pagination.Item>
                ))}
                <Pagination.Next
                  onClick={() => handlePageChange(teacherCurrentPage + 1)}
                  disabled={teacherCurrentPage === totalTeacherPages}
                />
                <Pagination.Last
                  onClick={() => handlePageChange(totalTeacherPages)}
                  disabled={teacherCurrentPage === totalTeacherPages}
                />
              </Pagination>
            </Col>
          </Row>
        </>
      )}

      {activeTab === "table2" && (
        <>
          {loading && <p>Loading Étudiant...</p>}
          <div className="table-responsive">
            <table className="table mt-3">
              <thead>
                <tr>
                  <th>N</th>
                  <th>Nom Prenom</th>
                  <th>Email</th>
                  <th>Date Naissance</th>
                  <th>CIN</th>
                  <th>Téléphone</th>
                  <th>Téléphone Parent</th>
                  <th>Pays</th>
                  <th>Ville</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {archivedStudents.map((student, index) => (
                  <tr key={student.id}>
                    <td>{index + 1}</td>
                    <td>
                      {capitalizeFirstLetter(student.nom)}{" "}
                      {capitalizeFirstLetter(student.prenom)}
                    </td>
                    <td>{student.email}</td>
                    <td>{moment(student.birthday).format("YYYY/MM/DD")}</td>
                    <td>{student.cin?.toUpperCase()}</td>
                    <td>{student.phone}</td>
                    <td>{student.parent_phone}</td>
                    <td>{capitalizeFirstLetter(student.country)}</td>
                    <td>{capitalizeFirstLetter(student.city)}</td>
                    <td className="d-flex col-12 justify-content-center">
                      <FaTrash
                        className="m-2"
                        style={{ color: "red" }}
                        onClick={() => handleDeleteClick(student.id, true)}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          {/* Pagination Controls for Students */}
          <Row className="justify-content-center">
            <Col xs="auto">
              <Pagination>
                <Pagination.First
                  onClick={() => handlePageChange(1)}
                  disabled={studentCurrentPage === 1}
                />
                <Pagination.Prev
                  onClick={() => handlePageChange(studentCurrentPage - 1)}
                  disabled={studentCurrentPage === 1}
                />
                {Array.from(
                  { length: Math.ceil(totalStudents / pageSize) },
                  (_, index) => (
                    <Pagination.Item
                      key={index + 1}
                      active={index + 1 === studentCurrentPage}
                      onClick={() => handlePageChange(index + 1)}
                    >
                      {index + 1}
                    </Pagination.Item>
                  )
                )}
                <Pagination.Next
                  onClick={() => handlePageChange(studentCurrentPage + 1)}
                  disabled={
                    studentCurrentPage === Math.ceil(totalStudents / pageSize)
                  }
                />
                <Pagination.Last
                  onClick={() =>
                    handlePageChange(Math.ceil(totalStudents / pageSize))
                  }
                  disabled={
                    studentCurrentPage === Math.ceil(totalStudents / pageSize)
                  }
                />
              </Pagination>
            </Col>
          </Row>
        </>
      )}

      {/* Confirmation Modal */}
      <Modal show={showModal} onHide={handleCloseModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirmation</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Êtes-vous sûr de vouloir supprimer 
          {isStudent ? " cet étudiant" : " ce Prof"}?
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseModal}>
            Annuler
          </Button>
          <Button variant="danger" onClick={confirmDelete}>
            supprimer
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};
