import React from "react";
import { Routes, Route } from "react-router-dom";
import Etudent from "./pages/Etudent/Etudent";
import Dashboard from "./pages/Dashboard/Dashboard";
import { Profs } from "./pages/Profs/Profs";
import Login from "./Auth/Login/Login";
import Register from "./Auth/Register/Register";
import PersistLogin from "./hooks/PersistLogin";
import { Home } from "./pages/Home/Home";
import { Choix } from "./pages/choix/Choix";
import { Formation } from "./pages/formation/Formation";
import { Groupe } from "./pages/Groupe/Groupe";
import Calendarier from "./pages/calendar/Calendarier";
import { GroupeEtudent } from "./pages/Groupe/GroupeEtudent";
import { Paiement } from "./pages/Paiement/Paiement";
import { Archive } from "./pages/Archive/Archive";
import CheckAuth from "./controller/CheckAuth";
import { ToastContainer } from "react-toastify";
import RequestPasswordReset from "./Auth/RestPassword/RequestPasswordReset";
import ResetPassword from "./Auth/RestPassword/ResetPassword";
import RequiredRules from "./controller/RequiredRules";

import { Forbidden } from "./Errors/Forbidden/Forbidden";
import { NotFound } from "./Errors/notFound/NotFound";
import Internal500 from "./Errors/500/Internal500";
import { Unauthorized } from "./Errors/Unauthorized/Unauthorized";
import { Helmet } from "react-helmet";
import { Site } from "./pages/Site/Site";
import { Contact } from "./pages/Site/components/Sections/Contact";
import VerifyEmailPage from "./Auth/VerifyEmail/VerifyEmailPage";

const icon = process.env.REACT_APP_BASE_URL + '/public/icon.png';

function App() {
  return (
    <div className="App">
      <Helmet>
      <title>Gycole - Plateforme de Gestion pour Centres Éducatifs et Cours de Soutien</title>
      <link  rel="icon" type="image/png"  href={icon} />
        <link rel="preconnect" href="https://fonts.googleapis.com" />
        <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin />
        <meta
          name="description"
          content="Plateforme éducative pour la gestion des étudiants, des professeurs, des paiements et plus encore. Facilitez l'administration scolaire avec des outils modernes."
        />
        <meta
          name="keywords"
          content="éducation, gestion scolaire, étudiants, professeurs, paiements, plateforme éducative , gycole , Gycole"
        />
        <meta name="author" content="GyCole" />
        <meta property="og:title" content="Gycole - Plateforme de Gestion pour Les Centres Éducatifs et Cours de Soutien" />
        <meta
          property="og:description"
          content="Gestion simple et efficace de vos sessions de soutien scolaire, suivi personnalisé et organisation optimisée."
        />
        <meta property="og:url" content="https://gycole.com"/>
        
        <meta property="og:image" content="https://i.postimg.cc/KvDRtp9b/Leonardo-Phoenix-a-cinematic-lowkey-lit-photograph-of-a-middle-3.jpg" />
      </Helmet>
      <Routes>
        {/*front end home  */}
        <Route path="/" element={<Site />} />
        <Route path="/contact" element={<Contact />} />
        {/*front end home  */}
        <Route path="/register" element={<Register />} />
        <Route path="/login" element={<Login />} />
        <Route
          path="/request-reset-password"
          element={<RequestPasswordReset />}
        />
        <Route path="/reset-password/:token" element={<ResetPassword />} />
        <Route path="/verify-email/:token" element={<VerifyEmailPage />} />
        <Route element={<PersistLogin />}>
          <Route element={<CheckAuth />}>
            <Route
              element={
                <RequiredRules allowedRules={["Secretary", "Director"]} />
              }
            >
              <Route element={<Dashboard />}>
                <Route index path="dashboard/accueil" element={<Home />} />
                <Route path="dashboard/Calendrier" element={<Calendarier />} />
                <Route path="dashboard/etudiant" element={<Etudent />} />
                <Route path="dashboard/profs" element={<Profs />} />
                <Route
                  path="dashboard/formation-groupe"
                  element={<Formation />}
                />
                <Route path="dashboard/choix" element={<Choix />} />
                <Route
                  path="dashboard/groups/:formationId"
                  element={<Groupe />}
                />
                <Route
                  path="/dashboard/groupe-etudiant/:groupId"
                  element={<GroupeEtudent />}
                />
                <Route
                  path="/dashboard/Paiement-etudiant"
                  element={<Paiement />}
                />
                <Route element={<RequiredRules allowedRules={["Director"]} />}>
                  <Route path="/dashboard/archive" element={<Archive />} />
                </Route>
                <Route element={<RequiredRules allowedRules={["Director"]} />}>
                </Route>
              </Route>
            </Route>
            {/* admin */}
          </Route>
        </Route>

        {/* errors */}
        <Route path="/Forbidden" element={<Forbidden />} />
        <Route path="/not-found" element={<NotFound />} />
        <Route path="/Unauthorized" element={<Unauthorized />} />
        <Route path="/internal-error" element={<Internal500 />} />
        <Route path="*" element={<NotFound />} />
        {/* errors */}
      </Routes>

      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
      />
    </div>
  );
}

export default App;
